export const tituloEstatus2 = (estatus) => {
  if (estatus === 1) return "Contactando cliente";
  return "Cliente contactado";
};
export const tituloEstatus3 = (estatus) => {
  if (estatus < 2) return "Por recolectar";
  if (estatus === 2) return "Recolectando docs";
  return "Docs recolectados";
};

export const tituloEstatus4 = (estatus) => {
  if (estatus < 3) return "Por validar";
  if (estatus === 3) return "Validando docs";
  return "Docs validados";
};

export const tituloEstatus5 = (estatus) => {
  if (estatus < 4) return "Por solicitar guia";
  if (estatus === 4) return "Solicitando guia";
  return "Guia solicitada";
};

export const tituloEstatus6 = (estatus) => {
  if (estatus < 5) return "Por confirmar guia";
  if (estatus === 5) return "Confirmando guia";
  return "Guia confirmada";
};

export const tituloEstatus7 = (estatus) => {
  if (estatus < 6) return "Por entregar docs";
  if (estatus === 6) return "Entregando docs";
  return "Finalizado";
};
