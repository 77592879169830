import { Button, Col, DatePicker, Input, Row } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setFiltroPt, startGetPTServicios, startGetXLSPTServicios, } from "../../../context/actions/ptActions";
import { EstatusPTDropdown } from "../../../components/dropdown/EstatusPTDropdown";
import axios from 'axios';
import { URL_INCIDENCIAS } from '../../../constants/url';

const {RangePicker} = DatePicker;

export const Controles = () => {
  const [ fechaAqui, setFechaAqui ] = useState();
  const dispatch = useDispatch();
  const {estatusId, guia,} = useSelector(
    (state) => state.ptReducer
  );


  function downloadFile() {
    axios({
      url: `${ URL_INCIDENCIAS }/GetXlsIncidenciasPT`,
      method: 'GET',
      responseType: 'blob',
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([ response.data ]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'xlsIncidenciasPT.xlsx');
      document.body.appendChild(link);
      link.click();
    });
  }


  const handleFechas = (val1, val2) => {
    dispatch(setFiltroPt(val2, "fechas"));
    setFechaAqui(val1);
  };

  return (
    <Row gutter={ 24 } style={ {marginBottom: 5} }>
      <Col span={ 2 }/>
      <Col span={ 5 }>
        <RangePicker
          placeholder={ [ "Inicio", "Fin" ] }
          format="YYYY-MM-DD"
          size="small"
          value={ fechaAqui }
          onChange={ handleFechas }
          style={ {width: "100%"} }
        />
      </Col>
      <Col span={ 5 }>
        <EstatusPTDropdown
          value={ estatusId }
          onChange={ (val) => dispatch(setFiltroPt(val, "estatusId")) }
        />
      </Col>

      <Col span={ 5 }>
        <Input
          value={ guia }
          onChange={ ({target}) => dispatch(setFiltroPt(target.value, "guia")) }
          size="small"
          placeholder="guia / siniestro"
        />
      </Col>
      <Col span={ 1.5 }>
        <Button
          onClick={ () => dispatch(startGetPTServicios()) }
          size="small"
          danger
        >
          Filtrar
        </Button>
      </Col>

      <Col span={ 2.5 }>

        <Button
          onClick={ downloadFile }
          size="small"
          danger
        >
          Incidencias XLS
        </Button>

      </Col>

      <Col span={ 2 }>
        <Button
          onClick={ () => dispatch(startGetXLSPTServicios()) }
          size="small"
          danger
        >
          Descargar XLS
        </Button>
      </Col>

    </Row>
  );
};
