import React from "react";
import { Forma } from "./_Forma";
import { Listado } from "./_Listado";

export const DetalleArchivos = () => {
  return (
    <div>
      <Forma />
      <Listado />
    </div>
  );
};
