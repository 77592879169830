import { Timeline } from 'antd';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { startGetHistorial } from '../../../context/actions/historialActions';
import { useParams } from 'react-router';
import { DayjsConfig } from '../../../utils/dayjs.config';
import { createColorUser } from './utils/getColorByUser';

export const TimeLineRobos = () => {
  const { id } = useParams()
  const historialRobos = useSelector(state => state.robosReducer.historialRobos)
  const format = DayjsConfig('DD-MM-YYYY - HH:mm')
  const dispatch = useDispatch();
  useEffect(() => {
    void dispatch(startGetHistorial(id, 3));
  }, []);


  const colorUser = createColorUser(historialRobos)
  const items = historialRobos.map(h => {
    return {
      color: 'green',
      children: <p> {format(h.fecha)}  <span style={{color: colorUser(h.nombre), fontWeight: 'bold'}}>{h.nombre}:</span>   {h.texto}   </p>
    }
  })

  return (
    <>
      <div className="cajaHistorial">
        <Timeline items={items}/>
      </div>
    </>

  )
}
