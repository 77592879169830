import { Button, Col, Input, InputNumber, Row } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { startPostConcesionarioCosto } from "../../../../../context/actions/concesionariosActions";
import { DescripcionCostos } from "../../../../../components/dropdown/DescripcionCostos";

export const Forma = () => {
  const [datos, setDatos] = useState({});
  const dispatch = useDispatch();
  const { id } = useParams();
  //
  const handleChangeTxt = ({ target }) => {
    setDatos({
      ...datos,
      [target.name]: target.value,
    });
  };

  const handleChange = (val, target) => {
    setDatos({
      ...datos,
      [target]: val,
    });
  };

  const handleClick = () => {
    dispatch(
      startPostConcesionarioCosto({ ...datos, ServicioConcesionariosId: id })
    );
    setDatos({});
  };

  ///////////////////
  return (
    <Row gutter={10}>
      <Col span={16}>
        <div>Tipo de costo</div>
        <DescripcionCostos
          value={datos.descripcion}
          onChange={(val) => handleChange(val, "descripcion")}
        />
      </Col>
      <Col span={6}>
        <div>Costo</div>
        <InputNumber
          value={datos.costoInicial}
          onChange={(val) => handleChange(val, "costoInicial")}
          name="costo"
          style={{ width: "100%" }}
          size="small"
        />
      </Col>
      <Col span={2} style={{ paddingTop: 18 }}>
        <Button onClick={handleClick} size="small" danger>
          OK
        </Button>
      </Col>
    </Row>
  );
};
