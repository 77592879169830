import dayjs from 'dayjs'
import 'dayjs/locale/es'

dayjs.locale('es') // use locale globally
export const DayjsConfig = (format) => {
  return (date) => {
    return dayjs(date).isValid() ? dayjs(date).format(format) : null
  }
}

export const getDateInstanceOrNull = (date) => {
  return dayjs(date).isValid() ? dayjs(date): null
}