import { types } from "../types/types";

const initialState = {
  robos: [],
  roboDetalles: {},
  historialRobos: [],
  comunicacionRobos: [],
  comunicacionesInicial: []
};


export const robosReducer = (state = initialState, action) => {
  //
  switch (action.type) {
    case types.storeRobos:
      return {
        ...state,
        robos: action.payload,
      };
    case types.storeRoboDetalles:
      return {
        ...state,
        roboDetalles: action.payload,
      };
    case types.storeHistorialRobos:
      return {
        ...state,
        historialRobos: action.payload,
      };
    case types.storeComunicacionRobos:
      return {
        ...state,
        comunicacionRobos: action.payload,
      };
    case types.storeComunicacionesIncial:
      return {
        ...state,
        comunicacionesInicial: action.payload,
      };
    default:
      return state;
  }
}
