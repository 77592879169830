import { Col, Row } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { formatCurrency } from "../../../../../utils/formatCurrency";

export const ListadoTotales = () => {
  const { costos } = useSelector(
    (state) => state.concesionariosReducer.concesionariosServicio
  );

  let totalInicial = 0;
  let totalAutorizado = 0;

  costos.map((s) => {
    totalInicial += s.costoInicial;
    totalAutorizado += s.costoAutorizado;
  });
  return (
    <Row className="rowItemElemento">
      <Col span={11} style={{ fontWeight: "600" }}>
        Total
      </Col>
      <Col span={6} style={{ fontWeight: "600", textAlign: "right" }}>
        {formatCurrency(totalInicial)}
      </Col>
      <Col span={5} style={{ fontWeight: "600", textAlign: "right" }}>
        {formatCurrency(totalAutorizado)}
      </Col>
    </Row>
  );
};
