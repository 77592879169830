import React from 'react';


import { Menu } from 'antd';
import { useMenuItems } from './useMenuItems';


export const MenuLayout = () => {


  const { openKeys, items, goTo, onOpenChange } = useMenuItems()

  return (
    <Menu
      mode="inline"
      openKeys={openKeys}
      onOpenChange={onOpenChange}
      onClick={val => goTo(val)}
      items={items}
    />
  )
}
