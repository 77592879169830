import React from "react";
import { useSelector } from "react-redux";
import { Row10 } from "../../../components/row/Row10";
import { Col_12_8_6_4 } from "../../../components/col/Col_12_8_6_4";
import LabelValor from "../../../components/label/LabelValor";
import { Col_doble } from "../../../components/col/Col_doble";
import { cortaString } from "../../../utils/cortaString";
import { formatCurrency } from "../../../utils/formatCurrency";
import { SigsaId } from "./inputs/_SigsaId";
import { FechaLiberacion } from "./inputs/_FechaLiberacion";

export const Seccion1 = () => {
  const { concesionariosServicio: c } = useSelector(
    (state) => state.concesionariosReducer
  );

  /*
    Cálculo costo total y costo incial
  */
  let costoTotalAutorizado = 0;
  let costoTotalIncial = 0;
  const { costos } = useSelector(
    (state) => state.concesionariosReducer.concesionariosServicio
  );
  costos.map((s) => {
    costoTotalAutorizado += s.costoAutorizado;
    costoTotalIncial += s.costoInicial;
  });
  return (
    <>
      <Row10>
        <Col_12_8_6_4>
          <LabelValor label="Folio Escotel" valor={c.folio} />
        </Col_12_8_6_4>

        <Col_doble>
          <LabelValor label="Folio único" valor={c.folioUnico} />
        </Col_doble>
        <Col_12_8_6_4>
          <LabelValor
            label="Fecha de siniestro"
            valor={cortaString(c.fechaSiniestro || "", 10, true)}
          />
        </Col_12_8_6_4>
        <Col_12_8_6_4>
          <LabelValor
            label="Fecha de reporte"
            valor={cortaString(c.fechaReporteSiniestro || "", 10, true)}
          />
        </Col_12_8_6_4>
        <Col_12_8_6_4>
          <LabelValor
            label="Fecha de asignación"
            valor={cortaString(c.fechaAsignacion || "", 10, true)}
          />
        </Col_12_8_6_4>
        <Col_doble>
          <LabelValor label="Titular de póliza" valor={c.titularPoliza} />
        </Col_doble>
      </Row10>
      <Row10>
        <Col_12_8_6_4>
          <LabelValor label="Siniestro" valor={c.siniestro} />
        </Col_12_8_6_4>
        <Col_12_8_6_4>
          <LabelValor label="Póliza" valor={c.poliza} />
        </Col_12_8_6_4>
        <Col_12_8_6_4>
          <LabelValor
            valorStyle={{ textAlign: "right" }}
            label="Costo Tope"
            valor={c.costoTopeGNP}
          />
        </Col_12_8_6_4>{" "}
        <Col_12_8_6_4>
          <LabelValor
            valorStyle={{ textAlign: "right" }}
            label="Costo inicial"
            valor={formatCurrency(costoTotalIncial)}
          />
        </Col_12_8_6_4>
        <Col_12_8_6_4>
          <LabelValor
            valorStyle={{ textAlign: "right" }}
            label="Propuesto GNP"
            valor={formatCurrency(c.costoPropuestoGNP)}
          />
        </Col_12_8_6_4>
        <Col_12_8_6_4>
          <LabelValor
            valorStyle={{ textAlign: "right" }}
            label="Costo autorizado"
            valor={formatCurrency(costoTotalAutorizado)}
          />
        </Col_12_8_6_4>
        <SigsaId />
        <FechaLiberacion />
      </Row10>
    </>
  );
};
