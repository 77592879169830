import { Alert } from "antd";
import React from "react";
import { useSelector } from "react-redux";

export const Verificar = () => {
  const { verificar } = useSelector(
    (state) => state.ptReducer.ptDetalle.servicioPT
  );

  if (verificar === true)
    return (
      <Alert
        style={{ margin: "10px 0px" }}
        message="Requiere revisión de desviaciones, consulta con tu supervisior "
        type="error"
        showIcon
      />
    );

  return <></>;
};
