import CardPage from '../../../layout/CardPage';
import { message, Select, Upload } from 'antd';
import { CloudUploadOutlined } from '@ant-design/icons';
import { BASE_URL_ROBOS } from '../../../constants/url';
import { post } from '../../../utils/fetch';
import { useState } from 'react';

const props = {
  multiple: false,
  maxCols: 1,
}

const options = [
  {value: 'inicial', label: 'Carga Inicial'},
  {value: 'localización', label: 'Localización'}
]

export function RobosCarga() {

  const [typeXsl, setTypeXsl] = useState(null)
  const uploadFile = async (file) => {
    // logica para subir  el archivo
    const formData = new FormData();
    formData.append("file", file);
    let data;

    if (typeXsl === 'inicial') {
      data = await post(`${BASE_URL_ROBOS}/LeerXLS`, formData);
    }
    if (typeXsl === 'localización') {
      data = await post(`${BASE_URL_ROBOS}/LeerLocalizacionXLS`, formData);
    }

    setTypeXsl(null)
    if (data.code === 0) {
      message.success("Archivo cargado correctamente");
      return null
    } else {
      message.error(data.error)
      return null
    }

  }

  const {Dragger} = Upload
  return (
    <CardPage
      titulo={'Cargar XLS: Robos'}
      {...props}
    >
      <Select value={typeXsl} onChange={(value) => setTypeXsl(value)} options={options}
              placeholder={'Seleccione el tipo de xls'} size="middle" style={{width: '200px', marginBottom: '20px'}}/>
      <Dragger
        action={(file) => uploadFile(file)}
        multiple={false}
        maxCount={1}
        disabled={!typeXsl}
        showUploadList={false}
      >

        <p className="ant-upload-drag-icon">
          <CloudUploadOutlined/>
        </p>

        <p className="ant-upload-text">Click o arrastra algún archivo</p>

        <p className="ant-upload-hint">
          Solamente soporta un solo archivo y de formato Excel
        </p>

      </Dragger>
    </CardPage>
  )
}