import { Button, Col, Divider, Row } from "antd";
import LabelValor from "../../../../components/label/LabelValor";
import { useSelector } from "react-redux";
import {
  DayjsConfig,
  getDateInstanceOrNull,
} from "../../../../utils/dayjs.config";
import { ModalFormEdit } from "../../../robos/roboDetalles/components/ModalEditDetalles";
import { useState } from "react";
import { useParams } from "react-router";
import { URL_PT } from "../../../../constants/url";
import { useGetFielsServicioPT } from "./useGetFielsServicioPT";
import { EditOutlined } from "@ant-design/icons";

export const NewFields = () => {
  const detallePT = useSelector(
    (state) => state.ptReducer.ptDetalle.servicioPT
  );
  const format = DayjsConfig("DD-MM-YYYY");
  const [open, setOpen] = useState(false);
  const { id } = useParams();
  const { fields } = useGetFielsServicioPT();
  const restFields = {
    fechaEnvioWhatsApp: getDateInstanceOrNull(detallePT?.fechaEnvioWhatsApp),
    fechaRespuestaWhatsApp: getDateInstanceOrNull(
      detallePT?.fechaRespuestaWhatsApp
    ),
    fechaRespuestaCorreo: getDateInstanceOrNull(
      detallePT?.fechaRespuestaCorreo
    ),
    fechaRecepcionPrimerDocumento: getDateInstanceOrNull(
      detallePT?.fechaRecepcionPrimerDocumento
    ),
    fechaEnvioCorreo: getDateInstanceOrNull(detallePT?.fechaEnvioCorreo),
    fechaRespuestaCorreo2: getDateInstanceOrNull(
      detallePT?.fechaRespuestaCorreo2
    ),
    fechaParoIndicador: getDateInstanceOrNull(detallePT?.fechaParoIndicador2),
    fechaEnvioWhatsApp2: getDateInstanceOrNull(detallePT?.fechaEnvioWhatsApp2),
    fechaRespuestaWhatsApp2: getDateInstanceOrNull(
      detallePT?.fechaRespuestaWhatsApp2
    ),
    fechaEnvioCorreo2: getDateInstanceOrNull(detallePT?.fechaEnvioCorreo2),
    fechaReactivacion: getDateInstanceOrNull(detallePT?.fechaReactivacion),
  };

  return (
    <>
      <Divider />

      <Row gutter={[10, 10]} style={{ marginBottom: "20px" }}>
        <Button
          icon={<EditOutlined />}
          onClick={() => setOpen(true)}
          type="primary"
        >
          Editar Fechas
        </Button>

        <ModalFormEdit
          fields={fields}
          open={open}
          setOpen={setOpen}
          defaultValues={{ ...detallePT, ...restFields }}
          baseUrl={URL_PT}
          width={1500}
          type={`UpdateServicio/${id}`}
        />
      </Row>
    </>
  );
};
