import { Radio } from 'antd';

const options = [
  {label: 'Si', value: true},
  {label: 'No', value: false},
]
export const RadioGroupCustom = ({label, onChange, defaultValue}) => {

  return (
    <>
      <div style={{display: 'inline-block', marginRight: '10px'}}>
        <p className="label" style={{marginBottom: 0, maxWidth: '200px'}}>{label}</p>
        <Radio.Group defaultValue={defaultValue} onChange={onChange} options={options} optionType={'button'}
                     buttonStyle={'solid'}/>
      </div>
    </>
  )
}