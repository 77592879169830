import React from "react";
import { UploadConNombre } from "../../../../components/upload/UploadConNombre";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { URL_PT } from "../../../../constants/url";
import { startGetPTServicio } from "../../../../context/actions/ptActions";
import { Button } from "antd";
import { startDownloadFile } from "../../../../context/actions/genericoActions";
import { RefacturacionDocs } from "./refacturacionDocs/RefacturacionDocs";

export const Forma = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { documentos } = useSelector((state) => state.ptReducer.ptDetalle);

  const url = `${URL_PT}/UploadFiles/${id}`;

  const handleClick = () => {
    const url2 = `${URL_PT}/GetArchivosZip/${id}`;
    dispatch(startDownloadFile(url2, `files_${id}.zip`));
  };

  ///
  return (
    <>
      <RefacturacionDocs />
      <hr />
      <Button disabled={documentos.length === 0} danger onClick={handleClick}>
        Descargar Todos
      </Button>
      <UploadConNombre
        url={url}
        dispatch1={() => dispatch(startGetPTServicio(id))}
      />
    </>
  );
};
