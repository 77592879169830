import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ListFile } from "../../../../../components/listas/ListFile";
import { URL_CONCESIONARIOS } from "../../../../../constants/url";
import { startDownloadFile } from "../../../../../context/actions/genericoActions";

export const Listado = () => {
  const dispatch = useDispatch();
  const { documentos } = useSelector(
    (state) => state.concesionariosReducer.concesionariosServicio
  );

  const handleClick = (doc) => {
    const url = `${URL_CONCESIONARIOS}/GetDocumento/${doc.id}`;
    dispatch(startDownloadFile(url, doc.nombreArchivo));
  };

  return (
    <div>
      {documentos.map((d, i) => (
        <ListFile key={i} doc={d} onClick={handleClick} />
      ))}
    </div>
  );
};
