import { Button, Col, Row, Skeleton, Space, Tooltip } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import UploadFile from "../../../components/upload/UploadFile";
import { startGetListaRefactura } from "../../../context/actions/flujoRefacturaActions";
import { Cabecera } from "./_Cabecera";
import { TotalRegistros } from "./_TotalRegistros";
import { SendOutlined } from "@ant-design/icons";
import { SkeletonPage } from "../../../layout/SkeletonPage";
import { CargarFileModal, CargarModeloRefactura } from "./_CargarFileModal";
import { URL_PT } from "../../../constants/url";

///
export const ListaModeloRefactura = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const { listaRefactura, cargando } = useSelector(
    (state) => state.flujoRefacturaReducer
  );

  ///

  if (cargando) return <SkeletonPage />;
  return (
    <>
      <TotalRegistros total={listaRefactura.length} />
      <Cabecera />
      {listaRefactura.map((a, i) => (
        <Row key={i} className="rowItemElemento">
          <Col span={4}>{a.idUnico}</Col>
          <Col span={4}>{a.numSiniestro}</Col>
          <Col span={4}>{a.numPoliza}</Col>
          <Col span={4}>{a.estatusId}</Col>
          <Col span={4}>
            <Space>
              <CargarFileModal
                title={`Cargar Modelo de Refactura para el folio ${a.id}`}
                tooltipTitle={`Cargar modelo de refactura ${a.id}`}
                url={`${URL_PT}/UploadFilesRefactura/${a.id}/ModeloRefactura`}
                recarga={() => dispatch(startGetListaRefactura(1, true))}
                tituloBoton="Modelo"
              />

              <Button
                onClick={() => navigate(`/PTDetalle/${a.id}`)}
                size="small"
                danger
              >
                <Tooltip title="Abrir servicio">
                  <SendOutlined />
                </Tooltip>
              </Button>
            </Space>
          </Col>
        </Row>
      ))}
    </>
  );
};
