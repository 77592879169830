import { Button, Form, Modal, Radio } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { post } from '../../utils/fetch';
import { URL_PT } from '../../constants/url';
import { useDispatch } from 'react-redux';
import { startGetPTServicio } from '../../context/actions/ptActions';
import { useParams } from 'react-router';

const options = [
  {label: 'Aprobado', value: 'aprobado'},
  {label: 'Rechazado', value: 'rechazado'},
];
export const ModalEditStatusDoc = ({open, setOpen, docId}) => {
  const dispatch = useDispatch();
  const {id} = useParams();

  async function onFinish(values) {
    values.DocumentoId = docId;
    const result = await post(`${URL_PT}/updateEstatusDocumento`, values)
    if (result.code === 0) {
      setOpen(false)
      void dispatch(startGetPTServicio(id))
    }

  }

  return (
    <>
      <Modal
        onCancel={() => setOpen(false)}
        open={open}
        footer={null}
        title={'Cambiar estatus del documento'}
      >
        <Form
          onFinish={onFinish}
          layout="vertical"
        >
          <Form.Item
            label="Estatus"
            name="estatusDocumento"
            rules={[{required: true, message: 'Por favor seleccione un estatus'}]}
          >
            <Radio.Group
              options={options}
              optionType="button"
              buttonStyle="solid"
            />
          </Form.Item>
          <Form.Item
            name={'motivoRechazo'}
            label='Motivo rechazo'
          >
            <TextArea size="middle"/>
          </Form.Item>
          <Button type="primary" htmlType="submit">Guardar</Button>
        </Form>

      </Modal>
    </>
  )
}