import { ListadoItem } from "./_ListadoItem";
import React from "react";
import { useSelector } from "react-redux";

export const Listado = () => {
  const { incidencias } = useSelector((state) => state.ptReducer.ptDetalle);

  return (
    <div>
      {incidencias.map((reg, i) => (
        <ListadoItem key={i} reg={reg} />
      ))}
    </div>
  );
};
