import { getFile } from "../../utils/fetch";
import { composeErrorNotification, } from "../../utils/composeNotification";
import { notification } from "./notificationActions";


export const startDownloadFile = (url, nombreArchivo) => {
  return async (dispatch) => {
    const result = await getFile(url, nombreArchivo);

    if (result.code === -1) {
      dispatch(
        notification(
          composeErrorNotification("Downoad documento", `${result.data}`)
        )
      );
    }
  };
};
