import { Button, DatePicker, Form, Input, message, Select } from 'antd';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { post } from '../../../../utils/fetch';
import { BASE_URL_ROBOS } from '../../../../constants/url';
import dayjs from 'dayjs';

const options = [
  {label: 'Notificado', value: 'notificado'},
  {label: 'Sin Contacto', value: 'sin contacto'},
  {label: 'Contacto Familiar', value: 'contactoFamiliar'},
  {label: 'No interesado', value: 'noInteresado'},
]

const opcionesFueraPeriodo = [
  {value: 'liberacion', label: 'Liberación'},
  {value: 'pago pt', label: 'Pago PT'},
  {value: 'no interesado', label: 'No interesado'},
]
export const CustomDropDown = ({label, statusName, style}) => {
  const {id} = useParams()
  const details = useSelector(state => state.robosReducer.roboDetalles)
  const [ form ] = Form.useForm();

  const onFinish = async (values) => {
    values.id = id
    const result = await post(`${ BASE_URL_ROBOS }/updateLocalizacion`, values)
    if (result.code === 0) {
      message.success('Estatus de localización actualizado')
    } else {
      message.error('Error al actualizar el estatus de localización')
    }
  }

  const submit = (values) => {

    const keys = Object.keys(values)
    const obj = keys.reduce((acc, key) => {
      if (form.isFieldTouched(key)) {
        acc[key] = values[key]
      }
      return acc
    }, {})
    void onFinish(obj)
  }
  const selected = Form.useWatch('estatusLocalizacion', form)
  return (
    <>
      <Form
        form={ form }
        onFinish={ submit }
        style={ {...style, fontWeight: 'bold'} }
        layout="vertical"
        initialValues={ {
          fechaNotificacionLocalizacion: details?.localizacion?.fechaNotificacionLocalizacion ? dayjs(details?.localizacion?.fechaNotificacionLocalizacion) : undefined,
          estatusLocalizacion: details?.localizacion?.estatusLocalizacion,
          contactoFamiliar: details?.localizacion?.contactoFamiliar,
          fueraPeriodo: details?.localizacion?.fueraPeriodo
        } }
      >
        <Form.Item
          label={ label }
          name={ statusName }
        >
          <Select options={ options } placeholder={ 'Selecciona una opción' }/>
        </Form.Item>

        { selected === 'contactoFamiliar' && <Form.Item name={ 'contactoFamiliar' }>
          <Input/>
        </Form.Item> }
        <Form.Item
          label={ 'Localización fuera de periodo' }
          name={ 'fueraPeriodo' }
        >
          <Select options={ opcionesFueraPeriodo } style={ {width: '100%'} }/>
        </Form.Item>
        <Form.Item label={ 'Fecha notificación localización' } name='fechaNotificacionLocalizacion'>
          <DatePicker style={ {width: '100%'} }/>
        </Form.Item>
        <Button htmlType="submit" type="primary">Guardar</Button>
      </Form>
    </>
  )
}