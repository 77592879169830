import { Col, Row } from "antd";
import React from "react";
import { RequiereAutorizacion } from "./RequiereAutorizacion";
import { Anulado } from "./Anulado";
import { Costos } from "./costos/Costos";
import { Comunicacion } from "./comunicacion/Comunicacion";
import { Requiere2doArrastre } from "./Requiere2doArrastre";
import { Archivos } from "./archivos/Archivos";
import { Incidencias } from "./incidencias/Incidencias";

export const Controles = () => {
  return (
    <Row gutter={20}>
      <Col span={4}>
        <RequiereAutorizacion />
      </Col>

      <Col span={4}>
        <Anulado />
      </Col>
      <Col span={4}>
        <Requiere2doArrastre />
      </Col>
      <Col span={12}>
        <Row gutter={10}>
          <Col span={6}>
            <Costos />
          </Col>
          <Col span={6}>
            <Comunicacion />
          </Col>
          <Col span={6}>
            <Archivos />
          </Col>
          <Col span={6}>
            <Incidencias />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
