import React, { useEffect, useState } from "react";
import { Col_12_8_6_4 } from "../../../../components/col/Col_12_8_6_4";
import LabelValor from "../../../../components/label/LabelValor";
import { dateFromString } from "../../../../utils/dateFromString";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { startUpdateServicioPT } from "../../../../context/actions/ptActions";
import { Input, Modal } from "antd";

export const Guia = () => {
  const [newValue, setNewValue] = useState();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const { servicioPT: c } = useSelector((state) => state.ptReducer.ptDetalle);

  const HandleOk = () => {
    dispatch(startUpdateServicioPT({ guia: newValue, id }));
    setOpen(false);
  };

  useEffect(() => {
    setNewValue(c.guia);
  }, [c.guia]);
  return (
    <>
      <Col_12_8_6_4>
        <LabelValor
          labelStyle={{ cursor: "pointer", textDecoration: "underline dotted" }}
          label="Guia"
          valor={c.guia}
          onClick={() => setOpen(true)}
        />
      </Col_12_8_6_4>
      <Modal
        title="Establecer Guia"
        onCancel={() => setOpen(false)}
        onOk={HandleOk}
        open={open}
      >
        <Input
          value={newValue}
          onChange={({ target }) => setNewValue(target.value)}
        />
      </Modal>
    </>
  );
};
