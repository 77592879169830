import { CloudUploadOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { message, Upload } from "antd";
import { URL_PT } from "../../../constants/url";
import { SelectC } from "../../../components/Select/SelectC";
import { post } from "../../../utils/fetch";

const {Dragger} = Upload;
export const FormaCarga = () => {
  const [tipoServicio, setTipoServicio] = useState()

  const uploadFile = async (file) => {
    if (!tipoServicio) {
      return message.error("Seleccione el tipo de servicio")
    }
    const formData = new FormData();
    formData.append("file", file);
    formData.append("tipoServicio", tipoServicio)
     const result =  await post(`${URL_PT}/LeerXLS`, formData);
    if (result.code ===  0){
      message.success("Archivo cargado correctamente");
    }else {
      message.error(result.error);
    }
  }
  const props = {
    name: "file", multiple: false, action: (file) => uploadFile(file), onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  return (<>
    <SelectC setTipo={setTipoServicio}/>
    <Dragger
      disabled={tipoServicio === undefined}
      showUploadList={false}
      {...props}>
      <p className="ant-upload-drag-icon">
        <CloudUploadOutlined/>
      </p>
      <p className="ant-upload-text">Click o arrastra algun archivo</p>
      <p className="ant-upload-hint">
        Solamente soporta un solo archivo y de formato Excel
      </p>
    </Dragger>
  </>);
};
