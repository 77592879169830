import React, { useEffect, useState } from "react";
import { Col_12_8_6_4 } from "../../../../components/col/Col_12_8_6_4";
import LabelValor from "../../../../components/label/LabelValor";
import { Modal } from "antd";
import LabelText from "../../../../components/input/LabelText";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { startUpdateServicio } from "../../../../context/actions/concesionariosActions";

///
export const SigsaId = () => {
  const { id } = useParams();
  const [valorId, setValorId] = useState();
  const dispatch = useDispatch();

  const { concesionariosServicio: c } = useSelector(
    (state) => state.concesionariosReducer
  );

  const [openSigsaIdModal, setOpenSigsaIdModal] = useState(false);

  const handleOkSigsaIdModal = () => {
    dispatch(startUpdateServicio({ sigsaId: valorId }, id));
    setOpenSigsaIdModal(false);
  };

  useEffect(() => {
    setValorId(c.sigsaId);
  }, [c.sigsaId]);
  ////
  return (
    <>
      <Col_12_8_6_4>
        <LabelValor
          labelStyle={{ cursor: "pointer", textDecoration: "underline dotted" }}
          onClick={() => setOpenSigsaIdModal(true)}
          label="ID Sigsa"
          valor={c.sigsaId}
        />
      </Col_12_8_6_4>
      <Modal
        title="Establecer ID de Sigsa"
        onCancel={() => setOpenSigsaIdModal(false)}
        onOk={handleOkSigsaIdModal}
        open={openSigsaIdModal}
      >
        <LabelText
          onChange={({ target }) => setValorId(target.value)}
          label="ID Sigsa"
          value={valorId}
        />
      </Modal>
    </>
  );
};
