import React from 'react';
import { Tag } from 'antd';

const TagEstatusRobo = ({serviceRobo}) => {
  if (serviceRobo.roboEstatusFinalID === 2) return <Tag color="green">Localizado</Tag>;
  if (serviceRobo.roboEstatusFinalID === 3) return <Tag color="red">No localizado</Tag>;
  if (serviceRobo.localizacion === false) return <Tag color="oranger">No Localizado</Tag>;
  if (serviceRobo.fechaTercerContacto) return <Tag color="yellow">Tercer Contacto</Tag>
  if (serviceRobo.fechaSegundoContacto) return <Tag color="cyan">Segundo Contacto</Tag>
  if (serviceRobo.fechaPrimerContacto) return <Tag color="blue">Primer Contacto</Tag>
  if (serviceRobo.fechaInicio) return <Tag color="purple">Inicio</Tag>
};

export default TagEstatusRobo;