import { ArrowLeftOutlined, ArrowRightOutlined, ClockCircleOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dateFromString } from "../../../../../utils/dateFromString";
import { Button, DatePicker, Modal, Space, Tooltip } from "antd";
import { useParams } from "react-router";
import { handleDate } from "../../../../../utils/dandleDate";
import locale from "antd/es/date-picker/locale/es_ES";
import {
  startUpdateEstatus,
  startUpdateServicioPT,
} from "../../../../../context/actions/ptActions";

//
export const GuiaConfirmada = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [newDate, setNewDate] = useState();
  const { estatusId, fechaGuiaConfirmada } = useSelector(
    (state) => state.ptReducer.ptDetalle.servicioPT
  );

  const handleOkConfirma = (avance) =>
    dispatch(startUpdateEstatus({ id, estatusId: estatusId + avance }));

  const changeDate = () => {
    const body = {
      fechaGuiaConfirmada: newDate,
      id,
    };
    dispatch(startUpdateServicioPT(body));
    setOpenModal(false);
  };

  if (estatusId === 5)
    return (
      <Space>
        <Tooltip title="Generar nueva guia">
          <Button
            shape="round"
            onClick={() => handleOkConfirma(-1)}
            size="small"
            danger
          >
            <ArrowLeftOutlined />
          </Button>
        </Tooltip>
        <Tooltip title="Avanzar">
          <Button
            shape="round"
            onClick={() => handleOkConfirma(1)}
            size="small"
            danger
          >
            <ArrowRightOutlined />
          </Button>
        </Tooltip>
      </Space>
    );

  if (estatusId >= 6)
    return (
      <div>
        <ClockCircleOutlined />
        <span
          style={{ cursor: "pointer", paddingLeft: 3 }}
          onClick={() => setOpenModal(true)}
        >
          {dateFromString(fechaGuiaConfirmada).dateStep}
        </span>
        <Modal
          open={openModal}
          title="Cambiar fecha"
          onCancel={() => setOpenModal(false)}
          onOk={changeDate}
        >
          <DatePicker
            showTime
            locale={locale}
            style={{ marginTop: 20 }}
            onChange={(val, strVal) => setNewDate(handleDate(val))}
          />
        </Modal>
      </div>
    );

  return <></>;
};
