import { Alert, Button, Col, message, Popconfirm } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { post } from "../../../../utils/fetch";
import { BASE_URL_ROBOS } from "../../../../constants/url";
import { getRoboDetalles } from "../../../../context/actions/robosActions";
import { useParams } from "react-router";
import { DayjsConfig } from "../../../../utils/dayjs.config";

export const ButtonEdit = () => {

  const details = useSelector(state => state.robosReducer.roboDetalles);
  const dispatch = useDispatch();
  const {id} = useParams()

  const format = DayjsConfig('DD/MM/YYYY')

  const anularGNP = async () => {
    const result = await post(`${BASE_URL_ROBOS}/updateRobo`, {
      id: (+id),
      anuladoGNP: true
    });
    if (result.code === 0) {
      message.success('Cambios guardados');
      void dispatch(getRoboDetalles(id));
    } else {
      message.error('Error al guardar cambios');
    }

  }

  async function moveToPT() {
    const result = await post(`${BASE_URL_ROBOS}/EnviaPT/${id}`)
    if (result.code === 0) {
      message.success(result.data);
      void dispatch(getRoboDetalles(id));
    } else {
      message.error(result.error);
    }
  }

  return (
    <>

      <Col span={12} style={{marginBottom: '10px'}}>

        {!details.anuladoGNP && <Popconfirm  onConfirm={anularGNP} title={'Anular'} description={'¿ Desea anular el servicio ?'}>
          <Button  size="small" danger>Anular GNP</Button>
        </Popconfirm>}
        {details.anuladoGNP && <Alert type="error"
                                      message={`Anulado: ${format(details?.fechaAnuladoGNP)} por ${details?.usuarioAnuladoGNP?.nombre ?? ''}  `}/>}
        {details?.roboEstatusFinalID === 2 && !details.anuladoGNP && !details.servicioPTID &&
          <Popconfirm title={'Mover a PT'} onConfirm={moveToPT}
                      description={`¿ Esta seguro de mover el servicio con id ${id} a pérdida total ?`}>
            <Button size={"small"} style={{marginLeft: '10px'}} danger>Mover a PT</Button>
          </Popconfirm>}
      </Col>
    </>
  )
}