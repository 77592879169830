import React from "react";
import { Col_12_8_6_4 } from "../../../components/col/Col_12_8_6_4";
import { Row10 } from "../../../components/row/Row10";
import LabelValor from "../../../components/label/LabelValor";
import { useSelector } from "react-redux";
import { Col_doble } from "../../../components/col/Col_doble";

export const Seccion2 = () => {
  const { concesionariosServicio: c } = useSelector(
    (state) => state.concesionariosReducer
  );

  return (
    <>
      <Row10>
        <Col_12_8_6_4>
          <LabelValor label="Tipo de vehículo" valor={c.tipoVehiculo} />
        </Col_12_8_6_4>
        <Col_12_8_6_4>
          <LabelValor label="Marca" valor={c.marca} />
        </Col_12_8_6_4>
        <Col_12_8_6_4>
          <LabelValor label="Modelo" valor={c.modelo} />
        </Col_12_8_6_4>
        <Col_12_8_6_4>
          <LabelValor label="Color" valor={c.color} />
        </Col_12_8_6_4>
        <Col_12_8_6_4>
          <LabelValor label="Placas" valor={c.placas} />
        </Col_12_8_6_4>
        <Col_12_8_6_4>
          <LabelValor label="No. Serie" valor={c.serie} />
        </Col_12_8_6_4>
      </Row10>

      <Row10>
        <Col_12_8_6_4>
          <LabelValor label="Tipo de gestión" valor={c.tipoGestion} />
        </Col_12_8_6_4>
        <Col_12_8_6_4>
          <LabelValor label="Afectado" valor={c.afectado} />
        </Col_12_8_6_4>

        <Col_12_8_6_4>
          <LabelValor label="Supervisoría" valor={c.supervisoria} />
        </Col_12_8_6_4>
        <Col_12_8_6_4>
          <LabelValor label="Nombre de supervisor" valor={c.nombreSupervisor} />
        </Col_12_8_6_4>
        <Col_12_8_6_4>
          <LabelValor label="Nombre de abogado" valor={c.nombreAbogado} />
        </Col_12_8_6_4>

        <Col_12_8_6_4>
          <LabelValor label="Correo abogado" valor={c.correoAbogado} />
        </Col_12_8_6_4>
        <Col_doble>
          <LabelValor
            label="Proveedor concesionado"
            valor={c.proveedorConcesionado}
          />
        </Col_doble>
        <Col_doble>
          <LabelValor
            label="Concesionado homologado"
            valor={c.concesionadoHomologado}
          />
        </Col_doble>
        <Col_12_8_6_4>
          <LabelValor label="Tel. concesionado" valor={c.telConcesionado} />
        </Col_12_8_6_4>
        <Col_12_8_6_4>
          <LabelValor
            label="Asesor de servicios gruas"
            valor={c.asesorServiciosGruas}
          />
        </Col_12_8_6_4>
      </Row10>
    </>
  );
};
