import { types } from "../types/types";

const initialState = {
  users: [],
  userDetails: {}
}

export const adminReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.saveUsers:
      return {
        ...state, // Usa el estado actual
        users: action.payload
      }
    case types.saveUser:
      return {
        ...state,
        userDetails: action.payload,
      }
    default:
      return state; // Devuelve el estado actual por defecto
  }
}
