import CardPage from '../../../layout/CardPage';
import { TablaRobos } from './TablaRobos';
import ControlesRobos from './ControlesRobos';

export default function ListaRobos(){
  return (
    <>
      <CardPage>
        <ControlesRobos/>
        <TablaRobos/>
      </CardPage>
    </>
  )
}