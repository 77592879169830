import { Button, Col, Flex, message, Row } from 'antd';
import { useSelector } from 'react-redux';
import LabelValor from '../../../../components/label/LabelValor';
import { RadioGroupCustom } from './RadioGroupCustom';
import { CustomDropDown } from './CustomDropDown';
import { useParams } from 'react-router';
import { post } from '../../../../utils/fetch';
import { BASE_URL_ROBOS } from '../../../../constants/url';
import { UseGetFieldsLocalizacion } from '../hooks/useGetFieldsLocalizacion';
import { ModalFormEdit } from './ModalEditDetalles';
import { useState } from 'react';
import { DayjsConfig } from "../../../../utils/dayjs.config";


export const DetallesLocalizacion = () => {
  const details = useSelector(state => state.robosReducer.roboDetalles);
  const {id} = useParams()
  const format = DayjsConfig('DD-MM-YYYY')
  const onChangeRadio = async ({target: {value}}, label) => {
    const result = await post(`${BASE_URL_ROBOS}/updateLocalizacion`, {id, [label]: value})
    if (result.code === 0) {
      message.success('Actualizado correctamente')
    } else {
      message.error('Error al actualizar')
    }

  }

  const {fields} = UseGetFieldsLocalizacion()
  const [openModalEditLocalizacon, setOpenModalEditLocalizacon] = useState(false)
  return (
    < >
      <div style={{minHeight: '500px'}}>
        <h2 className="card__titulo" style={{margin: '10px  0px'}}>Localización</h2>
        <Flex gap={30}>
          <div style={{width: '65%'}}>
            <Row gutter={[10, 10]} style={{marginBottom: '10px'}}>
              <Col span={12}>
                <LabelValor label={'Estado localización'} valor={details.localizacion?.estadoLocalizacion}/>
              </Col>

              <Col span={12}>
                <LabelValor label={'Ubicación unidad'} valor={details.localizacion?.ubicacionUnidad}/>
              </Col>

              <Col span={12}>
                <LabelValor label={'Condición unidad'} valor={details.localizacion?.condicionUnidad}/>
              </Col>


              <Col span={12}>
                <LabelValor label={'Nombre recuperador'} valor={details.localizacion?.recuperadorNombre}/>
              </Col>


              <Col span={12}>
                <LabelValor label={'Teléfono recuperador'} valor={details.localizacion?.recuperadorTelefono}/>
              </Col>

              <Col span={12}>
                <LabelValor label={'Email recuperador'} valor={details.localizacion?.recuperadorEmail}/>
              </Col>
              <Col span={12}>
                <LabelValor label={'Folio cerco'} valor={details.localizacion?.folioCerco}/>
              </Col>

              <Col span={12}>
                <LabelValor label={'Responsable'} valor={details.localizacion?.responsable}/>
              </Col>

              <Col span={6}>
                <LabelValor label={'Periodo localización'} valor={details.localizacion?.periodoLocalizacion}/>
              </Col>

              <Col span={6}>
                <LabelValor label={'Area Localización'} valor={details.localizacion?.areaLocalizacion}/>
              </Col>

              <Col xl={6}>
                <LabelValor label={'Fecha contacto recuperador'} valor={format(details?.localizacion?.fechaContactoRecuperador)}/>
              </Col>

              <Col xl={6}>
                <LabelValor label={'F. confirmación contacto recuperador'}
                            valor={format(details?.localizacion?.fechaConfirmacionContactoRecuperador)}/>
              </Col>

              <Col xl={6}>
                <LabelValor label={'Fecha confirmación localización'}
                            valor={format(details?.localizacion?.fechaConfirmacionLocalizacion)}/>
              </Col>

              <Col xl={6}>
                <LabelValor label={'Envío de CheckList'} valor={format(details?.localizacion?.envioChecklist)}/>
              </Col>

              <Col xl={6}>
                <LabelValor label={'Resultado Peritaje'} valor={details?.localizacion?.resultadoPeritaje}/>
              </Col>
              <Col span={6}>
                <Button style={{marginTop: '20px', marginLeft: '20px'}} danger size="small"
                        onClick={() => setOpenModalEditLocalizacon(true)}>Editar</Button>
              </Col>

            </Row>
          </div>
          <div style={{width: '30%'}}>

            <RadioGroupCustom
              defaultValue={details.localizacion !== null ? details?.localizacion?.contactoConRecuperador : null}
              onChange={(value) => onChangeRadio(value, 'contactoConRecuperador')}
              label={'Contacto con asegurado'}/>

            <RadioGroupCustom
              onChange={(value) => onChangeRadio(value, 'llamadaEnlazadaRecuperador')}
              defaultValue={details.localizacion ? details?.localizacion?.llamadaEnlazadaRecuperador : null}
              label={'Seguimiento con asegurado'}/>

            <CustomDropDown style={{marginTop: '20px'}} label={'Estatus localizacion'}
                            statusName={'estatusLocalizacion'}/>
          </div>
        </Flex>
      </div>
      <ModalFormEdit width={1500} defaultValues={details?.localizacion} fields={fields} open={openModalEditLocalizacon}
                     type="UpdateLocalizacion"
                     setOpen={setOpenModalEditLocalizacon}
                     title={'Editar  datos localización'}/>
    </>
  )
};
