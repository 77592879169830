import axios from "axios";

export function configurarInterceptor() {
  axios.interceptors.request.use((config) => {
    const token = localStorage.getItem("token");

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  });

}

export function response401Interceptor() {
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if ( error.response.status === 401) {
        localStorage.removeItem("token");
        if (window.location.pathname !== "/login"){

        window.location = "/login";
        }
      }
      if (error.response.status === 404) {
        return Promise.reject(error.response);
      }
      return Promise.reject(error);
    }
  );
}