import { Button, Drawer } from "antd";
import React, { useState } from "react";
import { DetalleComunicacion } from "./_DetalleComunicacion";

export const Comunicacion = () => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <div>
        <Button style={{ width: "100%" }} onClick={() => setOpen(true)} danger>
          Comunicación
        </Button>
      </div>
      <Drawer
        title="Historial"
        placement="right"
        onClose={() => setOpen(false)}
        open={open}
      >
        <DetalleComunicacion />
      </Drawer>
    </>
  );
};
