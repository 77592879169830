import { useNavigate } from 'react-router';
import { DayjsConfig } from '../../../utils/dayjs.config';

const format = DayjsConfig('DD-MM-YYYY')
export const useItemsLabel = (roboDetalles) => {
  const navigate = useNavigate()
  const firstRow = [

    {label: 'Id', value: roboDetalles.id},
    {
      label: "PT_ID",
      value: roboDetalles?.servicioPTID,
      onClick: () => navigate(`/PTDetalle/${roboDetalles?.servicioPTID}`)
    },
    {label: 'Fecha registro', value: format(roboDetalles.fechaRegistro)},
    {label: 'Fecha siniestro', value: roboDetalles.fechaSiniestro},
  ]
  const secondRow = [
    {label: 'Numero de siniestro', value: roboDetalles.numSiniestro},
    {label: 'Numero póliza', value: roboDetalles.numPoliza},
    {label: 'Lugar siniestro', value: roboDetalles.lugarSiniestro},
  ]
  const thirdRow = [

    {label: 'Marca', value: roboDetalles.marca},
    {label: 'Tipo', value: roboDetalles.tipo},
    {label: "Modelo", value: roboDetalles.modelo},
    {label: 'Número de serie', value: roboDetalles.numSerie},
  ]

  const fourthRow = [
    {label: 'Nombre asegurado', value: roboDetalles.nombreAsegurado},
    {label: 'Número fijo', value: roboDetalles.numFijo},
    {label: 'Número de celular', value: roboDetalles.numCelular},
    {label: 'Número alterno', value: roboDetalles.numAlterno},
  ]
  const fifthRow = [
    {label: 'Correo', value: roboDetalles.correo},
    {label: 'Periodo decretación', value: roboDetalles.perioDecretacion},
    {label: 'Fecha a decretar', value: roboDetalles.fechaDecretar},
  ]

  const six = [
    {label: 'Folio carpeta de investigación', value: roboDetalles.folioCarpetaInvestigacion},
    // {label: 'Folio Amis', value: roboDetalles.folioAmis},

  ]


  return { firstRow, secondRow, thirdRow, fourthRow, fifthRow, six}
}