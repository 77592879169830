import { Timeline } from 'antd';
import { createColorUser } from '../utils/getColorByUser';
import { DayjsConfig } from '../../../../utils/dayjs.config';

export const TimeLineComunicacion = ({ style, list}) => {
  const format = DayjsConfig('DD-MM-YYYY - HH:mm')

  const colorUser = createColorUser(list)

  const items = list.map((h) => {
    return {
      color: 'green',
      children: <div style={{marginTop: '5px'}}> {format(h.fecha)} <span
        style={{color: colorUser(h.nombre)}}>{h.nombre}</span> {h.motivo} via {h.medio} Contacto
        Exitoso: {h.contactoExitoso ? 'SI' : 'NO'}</div>
    }
  })
  return (
    <>
      <div style={{...style, }}>
        <Timeline items={items}/>
      </div>
    </>
  )
};
