import { useNavigate } from "react-router-dom";
import {
  BranchesOutlined,
  CarOutlined,
  CloudUploadOutlined,
  PoweroffOutlined,
  UnorderedListOutlined,
  UsergroupAddOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { startLogoff } from "../context/actions/loginActions";
import { areaToShowCargarDatos } from "../constants/const";

export const useMenuItems = () => {
  const {email, Modulo_concesionarios, Modulo_pt, Modulo_robos} = useSelector(
    (state) => state.userReducer.user
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openKeys, setOpenKeys] = useState([1]);
  const rootSubmenuKeys = [1, 2];

  const {area} = useSelector(state => state.userReducer.user);


  const concesionadosItems = {
    label: "Concesionados",
    key: "1",
    icon: <BranchesOutlined/>,
    children: [
      {
        label: "Servicios",
        key: "11",
        icon: <UnorderedListOutlined/>,
      },
      {
        label: "Cargar Datos",
        key: "12",
        icon: <CloudUploadOutlined/>,
      },
    ],
  };

  const robosItems = {
    label: 'Robos',
    icon: <CarOutlined/>,
    key: 'robos',
    children: [
      {
        label: 'Servicios',
        key: 'ListaRobos',
        icon: <UnorderedListOutlined/>,
      },
      {
        label: 'Cargar archivos',
        key: 'cargarArchivosRobos',
        icon: <CloudUploadOutlined/>,
      }
    ]
  }
  const ptItems = {
    label: "Perdida Total",
    key: "2",
    icon: <WarningOutlined/>,
    children: [
      {
        label: "Servicios",
        key: "21",
        icon: <UnorderedListOutlined/>,
      },
      {
        label: "Refactura",
        key: "22",
        icon: <UnorderedListOutlined/>,
      },
      {
        label: "Incidencias",
        key: "23",
        icon: <UnorderedListOutlined/>,
      },

    ],
  };
  if (areaToShowCargarDatos.includes(area?.toLowerCase())) {
    ptItems.children.push({
      label: "Cargar Datos",
      key: "24",
      icon: <CloudUploadOutlined/>,
    })
  }

  let closeSession = [
    {
      label: "Cerrar Sesión",
      key: "3",
      icon: <PoweroffOutlined/>,
    },
  ];
  const adminOptions = [
    {key: "users", label: "Users", icon: <UsergroupAddOutlined/>},
  ]
  let items = []
  if (Modulo_pt) items = [ptItems];
  if (Modulo_concesionarios) items = [concesionadosItems, ...items,]
  if (Modulo_robos || email === 'luis.zotelo@escotel.com.mx') items = [...items, robosItems]

  // items.push(...adminOptions)
  items.push(...closeSession)


  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) setOpenKeys(keys);
    else setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
  };

  const goTo = ({key}) => {
    if (key === "11") navigate("/ConsesionariosServicios");
    if (key === "12") navigate("/ConsesionariosCarga");

    if (key === "21") navigate("/PTServicios");
    if (key === "22") navigate("/ListaRefactura");
    if (key === "23") navigate("/ListaIncidencias");
    if (key === "24" && areaToShowCargarDatos.includes(area.toLowerCase())) navigate("/PTCarga");
    if (key === 'cargarArchivosRobos') navigate('/robosCarga')
    if (key === 'ListaRobos') navigate('/ListaRobos')
    if(key === 'users') navigate("/admin-users")
    if (key === "3") dispatch(startLogoff());
  };
  return {openKeys, items, goTo, onOpenChange};
};
