import { Select } from "antd";
import React from "react";

const { Option } = Select;

export const MotivoAuthDropdown = ({ value, onChange }) => {
  return (
    <Select
      size="small"
      value={value}
      onChange={onChange}
      style={{ width: "100%" }}
    >
      <Option value="Monto mayor">Monto mayor</Option>
    </Select>
  );
};
