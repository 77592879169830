import React from "react";
import { Forma } from "./_Forma";
import { Listado } from "./Listado";

export const Historial = () => {
  return (
    <div>
      <Forma/>
      <Listado/>
      {/*<HistorialAsegurado/>*/ }
    </div>
  );
};
