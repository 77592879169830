import { Select } from "antd";
import React from "react";
import { opcionesMotivoIncidencia } from "../../constants/const";

export const MotivoIncidenciaDropdown = ({ value, onChange }) => {
  return (
    <Select
      size="small"
      style={{ width: "100%" }}
      value={value}
      onChange={onChange}
      options={opcionesMotivoIncidencia}
      placeholder="Motivos"
    />
  );
};
