import { useSelector } from 'react-redux';
import { Steps } from 'antd';
import { useItemSteps } from './useItemSteps';

export default function StepsRobo() {
  const {
    fechaInicio,
    fechaPrimerContacto,
    fechaSegundoContacto,
    fechaTercerContacto,
    roboEstatusFinalID
  } = useSelector(state => state.robosReducer.roboDetalles)
  let id  = 0;
  if (fechaInicio) id = 0
  if (fechaPrimerContacto){
    id = 1
  }
  if (fechaSegundoContacto){
    id = 2
  }
  if (fechaTercerContacto){ id =3}
  if (roboEstatusFinalID !== 1 && roboEstatusFinalID !== undefined ) {id = 4}
  const {items} = useItemSteps(id)
  return (
    <>
      <Steps
        current={id}
        items={items}
      />
    </>
  )
}