import { ArrowRightOutlined, ClockCircleOutlined } from '@ant-design/icons';
import { DayjsConfig } from '../../../../utils/dayjs.config';
import { Button } from 'antd';

export const ControlStep = ({ date, onClick, title, currentStatus, numStatus, anulado}) => {
  const format = DayjsConfig("MMM DD HH:mm")
  if  (  currentStatus - numStatus ===  -1 ){
    return <Button disabled={anulado} icon={<ArrowRightOutlined />} shape="round" danger size="small" onClick={onClick}/>
  }else {
    return (
      <>
        {date
          && <span> <ClockCircleOutlined/> {format(date)} </span>
        }
      </>

    )
  }
}