import { ListadoItem } from "./_ListadoItem";
import React from "react";
import { useSelector } from "react-redux";
import { ListadoTotales } from "./_ListadoTotales";
import { ListadoTitulo } from "./_ListadoTitulo";

export const Listado = () => {
  const { costos } = useSelector(
    (state) => state.concesionariosReducer.concesionariosServicio
  );

  console.log(costos);
  return (
    <div>
      <ListadoTitulo />
      {costos.map((reg, i) => (
        <ListadoItem key={i} reg={reg} />
      ))}
      <ListadoTotales />
    </div>
  );
};
