import { types } from "../types/types";

const now = new Date();

now.setMonth(now.getMonth() - 3);

function formatDate(date) {
  const options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  };
  return date.toISOString().split("T")[0];
}
const init = {
  concesionariosServicios: [],
  concesionariosServicio: {
    costos: [],
  },
  cargandoDetalle: false,
  estatusCat: [],
  otroArreglo: [],
  filtro: {
    fechas: [formatDate(now), new Date().toISOString().split("T")[0]],
  },
  historial: [],
};

export const concesionariosReducer = (state = init, action) => {
  switch (action.type) {
    case types.storeConcesionarioHistorial:
      return {
        ...state,
        historial: action.payload,
      };
    case types.setFiltroConcesionarios:
      return {
        ...state,
        filtro: { ...state.filtro, ...action.payload },
      };
    case types.storeOtroArreglo:
      return {
        ...state,
        otroArreglo: action.payload,
      };

    case types.storeEstatusCat:
      return {
        ...state,
        estatusCat: action.payload,
      };

    case types.setCargandoDetalle:
      return {
        ...state,
        cargandoDetalle: action.payload,
      };

    case types.storeConcesionariosServicio:
      return {
        ...state,
        concesionariosServicio: action.payload,
      };

    case types.storeConcesionariosServicios:
      return {
        ...state,
        concesionariosServicios: action.payload,
      };

    default:
      return state;
  }
};
