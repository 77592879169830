import { useRef, useState } from "react";
import { useParams } from "react-router";
import { post } from "../../../../utils/fetch";
import { BASE_URL_ROBOS } from "../../../../constants/url";
import { message } from "antd";

export const useInput = (initialState, field) => {
  const [value, setValue] = useState(initialState);
  const debounceRef = useRef();
  const {id} = useParams();


  const saveChanges = async (newVal) => {
    const result = await post(`${BASE_URL_ROBOS}/updateRobo`, {
      id: (+id), [field]: newVal,
    });
    if (result.code === 0) {
      message.success('Cambios guardados');
    } else {
      message.error('Error al guardar cambios');
    }
  };

  const onChange = (e) => {
    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }
    const newVal = e.target.value;
    setValue(newVal);
    debounceRef.current = setTimeout(() => {
      void saveChanges(newVal);
    }, 1000);
  };

  return [value, onChange];
};