import { Button, Col, Row, Space, Tooltip } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Cabecera } from "./_Cabecera";
import { startGetDocumentosRefactura, startGetListaRefactura, } from "../../../context/actions/flujoRefacturaActions";
import { TotalRegistros } from "./_TotalRegistros";
import { SkeletonPage } from "../../../layout/SkeletonPage";
import { SendOutlined } from "@ant-design/icons";

export const ListaVoBo = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const { listaRefactura, cargando } = useSelector(
    (state) => state.flujoRefacturaReducer
  );

  useEffect(() => {
    void dispatch(startGetListaRefactura(3)); // Lista para carga modelo refactura
  }, []);

  const getFile = (id, tipo, nombre) => {
    const body = {
      id,
      tipo,
      nombre,
    };

    console.log(body);
    dispatch(startGetDocumentosRefactura(body));
  };

  ///////////////////
  if (cargando) return <SkeletonPage />;

  return (
    <>
      <TotalRegistros total={listaRefactura.length} />
      <Cabecera />
      {listaRefactura.map((a, i) => (
        <Row key={i} className="rowItemElemento">
          <Col span={4}>{a.idUnico}</Col>
          <Col span={4}>{a.numSiniestro}</Col>
          <Col span={4}>{a.numPoliza}</Col>
          <Col span={4}>{a.estatusId}</Col>
          <Col span={4}>
            <Space>
              <Button
                onClick={() => getFile(a.id, 1, `ModeloRefactura_${a.id}.pdf`)}
                size="small"
                danger
              >
                Ver Modelo
              </Button>
              <Button
                onClick={() => getFile(a.id, 2, `RefacturaPDF_${a.id}.pdf`)}
                size="small"
                danger
              >
                Ver PDF
              </Button>
              <Button
                onClick={() => getFile(a.id, 3, `RefacturaXML_${a.id}.xml`)}
                size="small"
                danger
              >
                Ver XML
              </Button>
              <Button
                onClick={() => navigate(`/PTDetalle/${a.id}`)}
                size="small"
                danger
              >
                <Tooltip title="Abrir servicio">
                  <SendOutlined />
                </Tooltip>
              </Button>
            </Space>
          </Col>
        </Row>
      ))}
    </>
  );
};
