import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, DatePicker, Form, Input, Modal, Radio, Row, Select } from 'antd';
import { useState } from 'react';
import { useParams } from 'react-router';
import { getRoboDetalles } from '../../../../context/actions/robosActions';
import { CheckOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { UseFormLocalizado } from '../useFormLocalizado';
import { post } from '../../../../utils/fetch';
import { URL_BASE } from '../../../../constants/url';

export const Localizado = ({anulado}) => {

  const {localizacion, roboEstatusFinalID} = useSelector(state => state.robosReducer.roboDetalles)

  const [ open, setOpen ] = useState(false)

  const [ form ] = Form.useForm()

  const localizado = Form.useWatch('estatusFinalID', form)

  const {id} = useParams()

  const dispatch = useDispatch()

  const options = [ {label: 'Localizado', value: 2}, {label: 'No localizado', value: 3}, ];

  const onfinish = async (values) => {
    values.id = id
    console.log(values)
    await post(`${URL_BASE}/api/robos/postConclusion`, values)
    form.resetFields()
    await dispatch(getRoboDetalles(id))
  }
  if (roboEstatusFinalID === 2) {
    return <div><CheckOutlined style={{color: 'green'}}/> Localizado</div>
  }
  if (roboEstatusFinalID === 3) {
    return <div><CloseCircleOutlined style={{color: 'red'}}/> localizado</div>
  }
  const {fieldsLabel} = UseFormLocalizado()


  const fiels = fieldsLabel.map(item => (<Col span={12} key={item.name}>
    <Form.Item
      label={item.label}
      name={item.name}
      rules={item.rules}
    >
      {item.type === 'date' ? <DatePicker style={{width: '100%'}}/> : item.type === 'select' ?
        <Select options={item.options} /> : <Input/>}
    </Form.Item>
  </Col>))
  return (<>
    <div>
      {localizacion === null &&
        <Button disabled={anulado} onClick={() => setOpen(true)} type="text" danger style={{fontWeight: 'bold'}}>Finalizar</Button>}
    </div>

    <Modal title={'Finalizar Servicio'} open={open} onCancel={() => setOpen(false)} footer={null} width={800}>
      <div style={{display: 'flex', flexDirection: 'column', marginTop: '20px'}}>
        <Form
          form={form}
          layout="vertical"
          onFinish={onfinish}
        >

          <Col span={12}>
            <Form.Item name={'estatusFinalID'} rules={[ {required: true, message: 'Estatus final requerido'} ]}>

              <Radio.Group
                options={options}
                optionType="button"
                buttonStyle="solid"
              />
            </Form.Item>

          </Col>

          <Row gutter={[ 10, 10 ]}>


            {localizado === 2 && fiels}
          </Row>

          <Button htmlType="submit" type="primary" style={{width: '200px'}}>Finalizar</Button>
        </Form>
      </div>
    </Modal>
  </>)
};
