export const CustomLabelWitchChildren = ({label, children}) => {
  return (
    <>
      <div style={{display: '', flexDirection: 'column' }}>
        <span className="label">{label}</span>
        <div style={{marginTop: '5px', display: 'block'}}>
          {children}
        </div>
      </div>
    </>
  )
}