import { Button, Flex, Form, Select, Typography } from 'antd';
import { SaveOutlined } from '@ant-design/icons';

const medios = [
  {label: 'Teléfono', value: 'Teléfono'},
  {label: 'Correo', value: 'Correo'},
  {label: 'WhatsApp', value: 'WhatsApp'}
]
const estatus = [
  {label: "Contactado", value: true},
  {label: "No contactado", value: false},
]
const rules = [
  {required: true}
]
export const FormComunication = ({onFinish, title, styleTitle, styleForm, motivos}) => {
    const [form] = Form.useForm();
    const submit = (values) => {
      onFinish(values)
      form.resetFields();
    }

    return (
      <>
        <div style={{margin: '0 auto'}}>

          {title && <Typography.Title level={4} style={{...styleTitle}}>{title}</Typography.Title>}

          <Form
            onFinish={submit}
            style={{...styleForm}}
            layout="vertical"
            form={form}
          >

            <Flex gap={10} align="center" justify="space-between">

              <Form.Item style={{width: '900px'}} name={'motivo'} label={'Motivo'} rules={rules}>
                <Select options={motivos}/>
              </Form.Item>

              <Form.Item style={{width: '400px'}} name={'medio'} label={'Medio'} rules={rules}>
                <Select options={medios}/>
              </Form.Item>

              <Form.Item style={{width: '500px'}} rules={rules} label={"Estatus"} name={'contactoExitoso'}>
                <Select options={estatus}/>
              </Form.Item>

              <Button htmlType="submit" type="primary" icon={<SaveOutlined/>}>Guardar</Button>

            </Flex>
          </Form>
        </div>
      </>
    )
  }
;
