import { consLogged } from "../../constants/consLogged";
import { URL_AUTH } from "../../constants/url";
import { get, post } from "../../utils/fetch";
import parseJwt from "../../utils/parseJwt";
import { types } from "../types/types";


export const startLogin = (body) => {

  return async (dispatch) => {
    dispatch(setLoginErr(null));
    const response = await post(`${URL_AUTH}/login`, { ...body });

    if (response.code !== -1) {
      localStorage.setItem("token", response.data);
      dispatch(storeUserData(parseJwt(response.data)));
      dispatch(setLogged(consLogged.LOGGED));
    } else {
      dispatch(setLoginErr(response.error));
    }


  };
};



export const verificaLogin = () => {
  return async (dispatch) => {
    //console.log("verificaLogin")

    const token = localStorage.getItem("token");

    if (token) {
      const u = parseJwt(token);
      dispatch(storeUserData(parseJwt(token)));
      dispatch(setLogged(consLogged.LOGGED));
    } else {
      dispatch(setLogged(consLogged.NOTLOGGED));
    }




  };
};

const storeUserData = (payload) => ({
  type: types.storeUserData,
  payload,
});

const setLogged = (payload) => ({
  type: types.setLogged,
  payload,
});

export const startLogoff = () => {
  return (dispatch) => {
    dispatch(setLogged(consLogged.NOTLOGGED));
    localStorage.removeItem("token");
  };
};

export const setLoginErr = (payload) => ({
  type: types.setLoginErr,
  payload,
});

//////
