import { Button, Drawer } from "antd";
import React, { useState } from "react";
import { DetalleArchivos } from "./_DetalleArchivos";
import { useSelector } from "react-redux";

export const Archivos = () => {
  const [open, setOpen] = useState(false);
  const { documentos } = useSelector(
    (state) => state.concesionariosReducer.concesionariosServicio
  );

  return (
    <>
      <div>
        <Button style={{ width: "100%" }} onClick={() => setOpen(true)} danger>
          Archivos ({documentos?.length})
        </Button>
      </div>
      <Drawer
        title="Archivos"
        placement="right"
        onClose={() => setOpen(false)}
        open={open}
      >
        <DetalleArchivos />
      </Drawer>
    </>
  );
};
