import { ListadoItem } from "./_ListadoItem";
import React from "react";
import { useSelector } from "react-redux";
import { ListadoTitulo } from "./_ListadoTitulo";

export const Listado = () => {
  const { incidencias } = useSelector(
    (state) => state.concesionariosReducer.concesionariosServicio
  );

  return (
    <div>
      {incidencias.map((reg, i) => (
        <ListadoItem key={i} reg={reg} />
      ))}
    </div>
  );
};
