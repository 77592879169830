export const UseGetFieldsLocalizacion = () => {
  const fields = [
    {label: 'Recuperador Nombre', name: 'recuperadorNombre'},
    {label: 'Recuperador Teléfono', name: 'recuperadorTelefono'},
    {label: 'Recuperador Email', name: 'recuperadorEmail'},
    {label: 'Folio Cerco', name: 'folioCerco'},
    {label: 'Responsable', name: 'responsable'},
    {label: 'Periodo Localización', name: 'periodoLocalizacion'},
    {
      label: 'Quien localiza',
      name: 'areaLocalizacion',
      type: 'select',
      options: [{label: 'Escotel', value: 'Escotel'}, {label: "GNP", value: 'GNP'}]
    },

    {label: 'F. contacto recuperador', name: 'fechaContactoRecuperador', type: 'datePicker'},
    {label: 'F. confirmación localización', name: 'fechaConfirmacionLocalizacion', type: 'datePicker'},
    {label: 'F. C. contacto recuperador', name: 'fechaConfirmacionContactoRecuperador', type: 'datePicker'},

    {
      label: 'Envío de checklist',
      name: 'envioChecklist',
      type: 'datePicker',
    },
    {label: 'Resultado Peritaje', name: 'resultadoPeritaje', type: 'textArea'}

  ]
  return {fields}
};
