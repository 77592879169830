import { Select } from "antd";

const options = [
  {value: 1, label: 'PT'},
  {value: 2, label: 'GM'},
  {value: 3, label: 'CTM'},
]
export const SelectC = ({setTipo}) => {
  return (
    <>
     <Select options={options} onChange={(value) => {
        setTipo(value)
      }} style={{width: "300px", marginBottom: "20px", marginTop: "20px"}}
              placeholder={"Seleccione la opción adecuada"}/>
    </>

  )
}