import { Button, Drawer } from "antd";
import React, { useState } from "react";
import { DetalleCostos } from "./_DetalleCostos";

export const Costos = () => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <div>
        <Button style={{ width: "100%" }} onClick={() => setOpen(true)} danger>
          Costos
        </Button>
      </div>

      <Drawer
        title="Costos"
        placement="right"
        onClose={() => setOpen(false)}
        open={open}
      >
        <DetalleCostos />
      </Drawer>
    </>
  );
};
