import React, { useEffect } from "react";
import CardPage from "../../../layout/CardPage";
import { useNavigate, useParams } from "react-router";
import { startGetPTServicio } from "../../../context/actions/ptActions";
import { useDispatch } from "react-redux";
import { startGetHistorial } from "../../../context/actions/historialActions";
import { Col, Divider, Row } from "antd";
import { Documentos } from "./documentos/Documentos";
import { Historial } from "./historial/Historial";
import { Timeline } from "./timeline/Timeline";
import { Seccion1 } from "./Seccion1";
import { AnuladoGnp } from "./anuladoGnp/AnuladoGnp";
import { FormInputDateLastContact } from "./components/FormInputDateLastContact";
import { NewFields } from "./new-fields-editable/NewFields";
import { Verificar } from "./verificar/Verificar";

export const PTDetalle = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const handleRegresar = () => navigate("/PTServicios");

  useEffect(() => {
    dispatch(startGetPTServicio(id));
    dispatch(startGetHistorial(id, 2));
  }, []);

  return (
    <>
      <CardPage
        titulo="Pérdida Total"
        tituloBoton="regresar"
        accionBoton={handleRegresar}
      >
        <Verificar />
        <Seccion1 />

        <NewFields />
        <FormInputDateLastContact />

        <AnuladoGnp />
        <Divider />
        <Timeline />
      </CardPage>
      <Row gutter={10}>
        <Col span={14}>
          <CardPage titulo="Historial">
            <Historial />
          </CardPage>
        </Col>
        <Col span={10}>
          <CardPage titulo="Documentos">
            <Documentos />
          </CardPage>
        </Col>
      </Row>
    </>
  );
};
