import { Button, Input } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { startPostHistorial } from "../../../../context/actions/historialActions";
import { useParams } from "react-router";

export const Forma = () => {
  const dispatch = useDispatch();
  const [texto, setTexto] = useState();
  const { id: IdServicio } = useParams();
  ////

  const handleClick = () => {
    dispatch(startPostHistorial({ IdServicio, texto, tipoServicio: 2 }));
    setTexto("");
  };

  ////
  return (
    <>
      <Input.TextArea
        value={texto}
        onChange={({ target }) => setTexto(target.value)}
        style={{ width: "100%" }}
      />
      <div style={{ textAlign: "right", marginTop: 5 }}>
        <Button onClick={handleClick} danger size="small">
          Agregar
        </Button>
      </div>
    </>
  );
};
