import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
} from "antd";
import { useParams } from "react-router";
import { post } from "../../../../utils/fetch";
import { BASE_URL_ROBOS } from "../../../../constants/url";
import { useDispatch } from "react-redux";
import { getRoboDetalles } from "../../../../context/actions/robosActions";
import dayjs from "dayjs";
import TextArea from "antd/lib/input/TextArea";
import { startGetPTServicio } from "../../../../context/actions/ptActions";

export const ModalFormEdit = ({
  title,
  open,
  setOpen,
  fields,
  width,
  defaultValues,
  type = "updateRobo",
  baseUrl = BASE_URL_ROBOS,
}) => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const onFinish = async (values) => {
    // get only Values touched
    const keys = Object.keys(values);
    const obj = keys.reduce((acc, key) => {
      if (form.isFieldTouched(key)) {
        acc[key] = values[key];
      }
      return acc;
    }, {});
    obj.id = id;
    const result = await post(`${baseUrl}/${type}`, obj);
    if (result.code === 0) {
      if (type === "updateRobo" || type === "UpdateLocalizacion") {
        void dispatch(getRoboDetalles(id));
        message.success(result.data);
        return;
      }
      void dispatch(startGetPTServicio(id));
      message.success(result.data);
    } else {
      message.error(result.error);
    }
  };
  return (
    <>
      <Modal
        title={title}
        onCancel={() => setOpen(false)}
        open={open}
        width={width}
        footer={null}
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={{
            ...defaultValues,
            fechaMedioPrimerContacto: dayjs(
              defaultValues?.fechaMedioPrimerContacto
            ).isValid()
              ? dayjs(defaultValues.fechaMedioPrimerContacto)
              : null,
            fechaConfirmacionContactoRecuperador: dayjs(
              defaultValues?.fechaConfirmacionContactoRecuperador
            ).isValid()
              ? dayjs(defaultValues.fechaConfirmacionContactoRecuperador)
              : null,
            fechaConfirmacionLocalizacion: dayjs(
              defaultValues?.fechaConfirmacionLocalizacion
            ).isValid()
              ? dayjs(defaultValues.fechaConfirmacionLocalizacion)
              : null,
            fechaContactoRecuperador: dayjs(
              defaultValues?.fechaContactoRecuperador
            ).isValid()
              ? dayjs(defaultValues.fechaContactoRecuperador)
              : null,
            envioChecklist: dayjs(defaultValues?.envioChecklist).isValid()
              ? dayjs(defaultValues.envioChecklist)
              : null,
          }}
          onFinish={onFinish}
        >
          <Row gutter={24}>
            {fields.map((field, index) => {
              console.log(field);
              if (field.type === "select") {
                return (
                  <Col key={index} span={6}>
                    <Form.Item label={field.label} name={field.name}>
                      <Select options={field.options} />
                    </Form.Item>
                  </Col>
                );
              } else if (field.type === "datePicker") {
                return (
                  <Col span={6} key={index}>
                    <Form.Item label={field.label} name={field.name}>
                      <DatePicker
                        style={{ width: "100%" }}
                        format={"DD-MM-YYYY"}
                      />
                    </Form.Item>
                  </Col>
                );
              } else if (field.type === "textArea") {
                return (
                  <Col xl={10} key={index}>
                    <Form.Item label={field.label} name={field.name}>
                      <TextArea
                        style={{ height: "100px", resize: "none" }}
                        showCount
                        maxLength={255}
                      />
                    </Form.Item>
                  </Col>
                );
              } else {
                return (
                  <Col key={index} span={6}>
                    <Form.Item label={field.label} name={field.name}>
                      <Input />
                    </Form.Item>
                  </Col>
                );
              }
            })}
          </Row>
          <Button htmlType="submit" type="primary">
            Guardar cambios
          </Button>
        </Form>
      </Modal>
    </>
  );
};
