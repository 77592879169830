import { CloudUploadOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Modal, Tooltip } from "antd";
import React, { useState } from "react";
import { UploadConNombreOculto } from "../../../components/upload/UploadConNombreOculto";

export const CargarFileModal = ({
  title,
  tooltipTitle,
  url,
  recarga,
  tituloBoton,
}) => {
  const [show, setShow] = useState(false);

  const cerrar = () => {
    setShow(false);
    recarga();
  };
  //
  return (
    <>
      <Button onClick={() => setShow(true)} size="small" danger>
        <Tooltip title={tooltipTitle}>
        <UploadOutlined /> {tituloBoton}
        </Tooltip>
      </Button>
      <Modal
        title={title}
        open={show}
        onCancel={() => setShow(false)}
        onOk={() => setShow(false)}
        footer={[
          <Button key="back" onClick={cerrar}>
            cerrar
          </Button>,
        ]}
      >
        <UploadConNombreOculto url={url} />
      </Modal>
    </>
  );
};
