import { ClockCircleOutlined } from '@ant-design/icons';
import { DayjsConfig } from '../../../utils/dayjs.config';

export const ClockWithDate = ({date}) => {
  const format = DayjsConfig("MMM DD HH:mm")
  return (
    <>
      <span> <ClockCircleOutlined /> {format(date)} </span>
    </>
  )
}