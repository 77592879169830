import React, { useEffect } from "react";
import CardPage from "../../../layout/CardPage";
import { startGetConcesionariosServicios } from "../../../context/actions/concesionariosActions";
import { useDispatch } from "react-redux";
import { Tabla } from "./_Tabla";
import { Controles } from "./_Controles";

export const ConsesionariosServicios = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(startGetConcesionariosServicios());
  });
  return (
    <CardPage titulo="Servicios de Concesionados">
      <Controles />
      <Tabla />
    </CardPage>
  );
};
