import React, { useEffect, useState } from "react";
import { Col_12_8_6_4 } from "../../../../components/col/Col_12_8_6_4";
import LabelValor from "../../../../components/label/LabelValor";
import { DatePicker, Modal } from "antd";

import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { startUpdateServicio } from "../../../../context/actions/concesionariosActions";
import { dateFromString } from "../../../../utils/dateFromString";

///
export const FechaLiberacion = () => {
  const [newDate, setNewDate] = useState();
  const { id } = useParams();
  const [valorId, setValorId] = useState();
  const dispatch = useDispatch();

  const { concesionariosServicio: c } = useSelector(
    (state) => state.concesionariosReducer
  );

  const [openSigsaIdModal, setOpenSigsaIdModal] = useState(false);

  const handleOkSigsaIdModal = () => {
    dispatch(startUpdateServicio({ fechaLiberacion: newDate }, id));
    setOpenSigsaIdModal(false);
  };

  useEffect(() => {
    setValorId(c.sigsaId);
  }, [c.sigsaId]);
  ////
  return (
    <>
      <Col_12_8_6_4>
        <LabelValor
          labelStyle={{ cursor: "pointer",textDecoration: "underline dotted" }}
          onClick={() => setOpenSigsaIdModal(true)}
          label="Fecha liberación"
          valor={dateFromString(c.fechaLiberacion).soloFecha}
        />
      </Col_12_8_6_4>
      <Modal
        title="Establecer fecha"
        onCancel={() => setOpenSigsaIdModal(false)}
        onOk={handleOkSigsaIdModal}
        open={openSigsaIdModal}
      >
        <DatePicker
          style={{ width: "100%" }}
          onChange={(val1, val2) => setNewDate(val2)}
        />
      </Modal>
    </>
  );
};
