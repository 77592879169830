import { EyeFilled } from '@ant-design/icons';
import { useNavigate } from 'react-router';
import TagEstatusRobo from './TagEstatusRobo';
import { DayjsConfig } from '../../../lib/config/dayjs.config';


export const useTableData = () => {
  const navigate = useNavigate();
  const format = DayjsConfig('DD-MM-YYYY')
  const columns = [
    {
      title: "ID",
      key: "id",
      dataIndex: "id",
    },
    {
      title: 'PT_ID',
      key: 'servicioPTID',
      dataIndex: 'servicioPTID',
    },
    {
      title: "Fecha siniestro",
      key: "fechaRegistro",
      render: (tag) => format(tag),
      dataIndex: "fechaSiniestro",
    },
    {
      title: "Numero Siniestro",
      key: "numSiniestro",
      dataIndex: "numSiniestro"
    },
    {
      title: 'Póliza',
      key: 'numPoliza',
      dataIndex: 'numPoliza'
    },
    {
      title: 'Asegurado',
      key: 'nombreAsegurado',
      dataIndex: 'nombreAsegurado'
    },
    {
      title: 'Periodo a decretar',
      key: 'perioDecretacion',
      dataIndex: 'perioDecretacion'
    },
    {
      title: 'Fecha a decretar',
      key: 'fechaDecretar',
      render: (tag) => format(tag),
      dataIndex: 'fechaDecretar'
    },
    {
      key: 'estatus',
      title: 'Estatus',
      render: tag => <TagEstatusRobo serviceRobo={tag}/>,
    },
    {
      key: 'AreaLocalización' ,
      title: "Area localización",
      dataIndex: "areaLocalizacion",
    },
    {
      key: 'fechaMedioPrimerContacto',
      title: "Fecha primer contacto",
      dataIndex: 'fechaMedioPrimerContacto',
      render: (tag) =>  tag ? format(tag) : '',
      filters: [{text: 'Contactados' ,value: true}, {text: 'No contactados', value: false}],
      onFilter: (value, record) =>  !!record?.fechaMedioPrimerContacto  === value
    },
    {
      key: 'numeroDocumentos',
      title: 'No. docs',
      render: tag => tag.numeroDocumentos ?? 0,
      filters: [{text: 'Documentos completos', value: true}, {text: 'Documentos incompletos', value: false}],
      onFilter: (value, record) => !!record.documentosCompletos === value
    },
    {
      title: '',
      key: 'action',
      render: (tag) => <EyeFilled
        className="botonIcono"
        onClick={() => navigate(`/roboDetalle/${tag.id}`)}/>
    }
  ]
  return {
    columns
  }
}