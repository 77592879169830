export const UseGetFielsDetalles = () => {
  const options = [
    {label: 'Correo', value: 'Correo'},
    {label: 'Teléfono', value: 'Telefono'},
    {label: 'WhatsApp', value: 'WhatsApp'}
  ]
  const fields = [
    {label: 'Lugar siniestro', name: 'lugarSiniestro'},
    {label: 'Marcar', name: 'marca'},
    {label: 'Tipo', name: 'tipo'},
    {label: 'Modelo', name: 'modelo'},
    {label: 'Número de serie', name: 'numSerie'},
    {label: 'Número celular', name: 'numCelular'},
    {label: 'Número fijo', name: 'numFijo'},
    {label: 'Número alterno', name: 'numAlterno'},
    {label: 'Correo', name: 'correo'},
    {label: 'Medio primer contacto', name: 'medioPrimerContacto', type: 'select', options},
    {
      label: 'Notificar no contactado',
      name: 'notificarNoContactado',
      type: 'select',
      options: [{label: 'Si', value: true}, {label: 'No', value: false}]
    },
    {label: 'F. Medio Primer Contacto', name: 'fechaMedioPrimerContacto', type: 'datePicker'},

    {label: 'Razón no contactado', name: 'razonNoContactado', type: 'textArea'},
  ]
  return {fields}
};
