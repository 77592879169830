import { Timeline } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { HistorialItem } from "./HistorialItem";

export const Listado = () => {
  const [ items, setItems ] = useState([]);
  const {historial} = useSelector((state) => state.historialReducer);

  useEffect(() => {
    let temp = [];
    historial.map((h) => {
      temp = [
        ...temp,
        {
          color: "green",
          children: <HistorialItem historial={h}/>,
        },
      ];
    });

    setItems(temp);
  }, [ historial ]);


  return (
    <div style={{maxHeight: '600px', overflow: 'auto', paddingTop: '10px'}}>
      <Timeline items={items}/>
    </div>
  );
};
