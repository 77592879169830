const randomColorsUser = [
  "blue",
  "brown",
  "yellow",
  "purple",
  "orange",
  "cyan",
]

export function createColorUser(historial) {
  const users = historial.map(h => h.nombre)
  const usersSet = new Set(users)
  const usersArray = Array.from(usersSet)

  return function(user) {
    const index = usersArray.indexOf(user)
    return randomColorsUser[index] ?? 'orange'
  }
}

