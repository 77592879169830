import { ClockCircleOutlined } from "@ant-design/icons";
import React from "react";
import { useSelector } from "react-redux";
import { dateFromString } from "../../../../../utils/dateFromString";

export const Inicio = () => {
  const { fechaIncio } = useSelector(
    (state) => state.concesionariosReducer.concesionariosServicio
  );
  return (
    <div>
      <ClockCircleOutlined /> {dateFromString(fechaIncio).dateStep}
    </div>
  );
};
