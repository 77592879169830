import React from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { URL_CONCESIONARIOS, URL_PT } from "../../../../../constants/url";
import { startGetConcesionariosServicio } from "../../../../../context/actions/concesionariosActions";
import { UploadConNombre } from "../../../../../components/upload/UploadConNombre";

export const Forma = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const url = `${URL_CONCESIONARIOS}/UploadFiles/${id}`;
  return (
    <UploadConNombre
      url={url}
      dispatch1={() => dispatch(startGetConcesionariosServicio(id, true))}
    />
  );
};
