import { Col, Row } from "antd";

export const Cabecera = () => {
  return (
    <Row>
      <Col span={4} className="subTitulo">
        Folio
      </Col>
      <Col span={4} className="subTitulo">
        Siniestro
      </Col>
      <Col span={4} className="subTitulo">
        Poliza
      </Col>
      <Col span={4} className="subTitulo">
        Estatus
      </Col>
    </Row>
  );
};
