import { CheckCircleOutlined } from "@ant-design/icons";
import React from "react";

export const Tache = ({ dato }) => {
  if (dato)
    return (
      <div style={{ textAlign: "center" }}>
        <CheckCircleOutlined style={{ color: "#08960a", fontSize: 18 }} />
      </div>
    );
  return <></>;
};
