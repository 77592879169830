import { ClockCircleOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { Button, DatePicker, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { dateFromString } from "../../../../../utils/dateFromString";
import { useParams } from "react-router";
import {
  startUpdateEstatus,
  startUpdateServicio,
} from "../../../../../context/actions/concesionariosActions";
import { useState } from "react";
import locale from "antd/es/date-picker/locale/es_ES";
import { handleDate } from "../../../../../utils/dandleDate";

export const Liberado = () => {
  const [show, setShow] = useState(false);
  const [newDate, setNewDate] = useState();

  const { id } = useParams();
  const dispatch = useDispatch();
  const { estatusId, fechaLiberacion } = useSelector(
    (state) => state.concesionariosReducer.concesionariosServicio
  );

  const handleClick = () =>
    dispatch(startUpdateEstatus({ id, estatusId: estatusId + 1 }));

  const changeDate = () => {
    const body = {
      fechaLiberacion: newDate,
    };
    dispatch(startUpdateServicio(body, id));
    setShow(false);
  };

  if (estatusId === 6)
    return (
      <Button shape="round" onClick={handleClick} size="small" danger>
        <ArrowRightOutlined />
      </Button>
    );

  if (estatusId >= 7)
    return (
      <>
        <div style={{ cursor: "pointer" }} onClick={() => setShow(true)}>
          <ClockCircleOutlined /> {dateFromString(fechaLiberacion).dateStep}
        </div>
        <Modal
          open={show}
          title="Cambiar fecha"
          onCancel={() => setShow(false)}
          onOk={changeDate}
        >
          <DatePicker
            showTime
            locale={locale}
            style={{ marginTop: 20 }}
            onChange={(val, strVal) => setNewDate(handleDate(val))}
          />
        </Modal>
      </>
    );

  return <></>;
};
