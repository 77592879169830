import { types } from '../types/types';
import { get, getFile, post } from '../../utils/fetch';
import { BASE_URL_ROBOS } from '../../constants/url';
import { notification } from './notificationActions';
import { composeErrorNotification } from '../../utils/composeNotification';


export const getRobos = (body = {}) => {
  return async (dispatch) => {
    const url = `${ BASE_URL_ROBOS }/getRobos`

    const result = await post(url, body)

    if (result.code === 0) {
      dispatch(storeRobosServices(result.data))
    }
  }
}


const storeRobosServices = (servicios) => ({
  type: types.storeRobos,
  payload: servicios,
});

export const downloadFile = (path) => {
  return async (dispatch) => {
    const url = `${ BASE_URL_ROBOS }/${ path }`;
    let name;
    if (path === 'DescargaRobos') {
      name = "registros_robos.xlsx"
    } else {
      name = "comunicaciones_robos.xlsx"
    }
    const result = await getFile(url, name);

    if (result.code !== 0) {
      dispatch(
        notification(composeErrorNotification("startGetXLS", `${ result.error }`))
      );
    }
  };
};

export const getRoboDetalles = (id) => {
  return async (dispatch) => {
    const url = `${ BASE_URL_ROBOS }/getRobo/${ id }`

    const result = await get(url)
    if (result.code === 0) {
      dispatch(storeRobosDetalles(result.data))
    } else {
      dispatch(
        notification(composeErrorNotification("getRoboDetalles", `${ result.error }`))
      );

    }
  }
}

export const getComunicaciones = (id, tipo) => {
  return async (dispatch) => {
    const url = `${ BASE_URL_ROBOS }/getComunicaciones/${ id }/${ tipo }`

    const result = await get(url)
    console.log(tipo, result)
    if (result.code === 0) {
      if (tipo === 'localizacion') {
        dispatch(storeComunicaciones(result.data))
      }
      if (tipo === 'inicial') {
        dispatch(storeIncial(result.data))
      }
    } else {
      dispatch(
        notification(composeErrorNotification("getComunicaciones", `${ result.error }`))
      );
    }
  }
}

const storeComunicaciones = (comunicaciones) => ({
  type: types.storeComunicacionRobos,
  payload: comunicaciones,
})

export const storeRobosDetalles = (detalles) => ({
  type: types.storeRoboDetalles,
  payload: detalles,
})
const storeIncial = (com) => ({
  type: types.storeComunicacionesIncial,
  payload: com,
})