import { Button, DatePicker, Flex, Form, Input } from 'antd';
import { useDispatch } from 'react-redux';
import { downloadFile, getRobos } from '../../../context/actions/robosActions';

const {RangePicker} = DatePicker;
const ControlesRobos = () => {
  const dispatch = useDispatch();
  const onFinish = (values) => {
    let body = {}
    if (values.date) {
      body.fechas = values?.date?.map((date) => date.format('YYYY-MM-DD'))
    }
    if (values.noSiniestro) {
      body.numSiniestro = values.noSiniestro
    }
    void dispatch(getRobos(body))
  }
  const [ form ] = Form.useForm();
  const getXLS = (path) => {
    void dispatch(downloadFile(path))
  }
  return (<>
      <Flex
        gap={ 20 }
        justify={ 'flex-end' }
        style={ {marginRight: '40px'} }
      >

        <Form
          form={ form }
          onFinish={ onFinish }
        >
          <Flex
            gap={ 20 }
            justify={ 'flex-end' }
          >
            <Form.Item name={ 'date' }>
              <RangePicker
                placeholder={ [ "Inicio", "Fin" ] }
                format="YYYY-MM-DD"
                size="small"
                style={ {width: '300px'} }
              />
            </Form.Item>

            <Form.Item
              name={ 'noSiniestro' }
            >
              <Input size="small" placeholder="No. Siniestro" style={ {width: '200px'} }/>
            </Form.Item>

            <Button htmlType="submit" danger size="small">Filtrar</Button>

          </Flex>

        </Form>

        <Button onClick={ () => getXLS('DescargaRobos') } danger size="small">Descargar XLS</Button>
        <Button onClick={ () => getXLS('DescargaComunicaciones') } danger size="small">Descargar comunicaciones</Button>
      </Flex>
    </>
  )
};

export default ControlesRobos;