import { Button, Col, Input, InputNumber, Row } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { startUpdateServicio } from "../../../../../context/actions/concesionariosActions";
import { useParams } from "react-router";

export const FormaPropuestoGnp = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { costoPropuestoGNP: costoPropuestoGNPAntes } = useSelector(
    (state) => state.concesionariosReducer.concesionariosServicio
  );
  const [costoPropuestoGNP, setCostoPropuestoGNP] = useState(
    costoPropuestoGNPAntes
  );
  const handleClick = () =>
    dispatch(startUpdateServicio({ costoPropuestoGNP }, id));
  return (
    <Row gutter={10}>
      <Col>
        <div>Costo Propuesto Gnp</div>
        <InputNumber
          value={costoPropuestoGNP}
          onChange={(val) => setCostoPropuestoGNP(val)}
          size="small"
          style={{ width: "100%" }}
        />
      </Col>
      <Col style={{ paddingTop: 18 }}>
        <Button onClick={handleClick} size="small" danger>
          OK
        </Button>
      </Col>
    </Row>
  );
};
