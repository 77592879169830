export const handleDate = (d1) => {
  const d2 = new Date(d1);
  const y = d2.getFullYear();
  const m = d2.getMonth() + 1;
  const mm = m > 9 ? m : `0${m}`;

  const d = d2.getDate();
  const dd = d > 9 ? d : `0${d}`;

  const h = d2.getHours();
  const hh = h > 9 ? h : `0${h}`;

  const min = d2.getMinutes();
  const minn = min > 9 ? min : `0${min}`;

  const s = d2.getSeconds();
  const ss = s > 9 ? s : `0${s}`;

  const final = `${y}-${mm}-${dd}T${hh}:${minn}:${ss}`;
  return final;
};
