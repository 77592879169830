import React from "react";
import { dateFromString } from "../../../../../utils/dateFromString";
import { Button } from "antd";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { startTerminarIncidencia } from "../../../../../context/actions/concesionariosActions";

export const FechaTermino = ({ fechaTermino, registroId, usuarioCierra }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  return (
    <>
      {fechaTermino !== null ? (
        <div>
          <b>Termina: </b>
          {dateFromString(fechaTermino).dateStep} -{" "}
          <span style={{ color: "orange" }}>{usuarioCierra}</span>
        </div>
      ) : (
        <div style={{ textAlign: "center" }}>
          <Button
            onClick={() => dispatch(startTerminarIncidencia(registroId, id))}
            size="small"
            danger
          >
            Cerrar Incidencia
          </Button>
        </div>
      )}
    </>
  );
};
