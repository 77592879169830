import { Row } from "antd";
import React from "react";

export const Row10 = ({ children }) => {
  return (
    <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
      {children}
    </Row>
  );
};
