import { Button, Col, Input, Row } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { startPostHistorial } from "../../../../../context/actions/historialActions";

export const Forma = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [texto, setTexto] = useState();

  ////

  const handleClick = () =>
    dispatch(startPostHistorial({ texto, IdServicio: id, tipoServicio: 1 }));
  //////////////
  return (
    <Row gutter={10}>
      <Col span={18}>
        <Input.TextArea
          name="texto"
          onChange={({ target }) => setTexto(target.value)}
        ></Input.TextArea>
      </Col>
      <Col span={6} style={{paddingTop: 28}}>
        <Button onClick={handleClick} size="small" danger>
          Agregar
        </Button>
      </Col>
    </Row>
  );
};
