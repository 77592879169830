import { Col, Row } from "antd";
import React from "react";

export const ListadoTitulo = () => {
  return (
    <Row>
      <Col span={11} style={{ textAlign: "center", fontWeight: 600 }}>
        Descripción
      </Col>
      <Col span={6} style={{ textAlign: "center", fontWeight: 600 }}>
        Costo Inicial
      </Col>
      <Col span={5} style={{ textAlign: "center", fontWeight: 600 }}>
        Costo Aut.
      </Col>
      <Col span={2}></Col>
    </Row>
  );
};
