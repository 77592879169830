import { ClockWithDate } from './ClockWithDate';
import { useDispatch, useSelector } from 'react-redux';
import { ControlStep } from './steps/ControlStep';
import { useParams } from 'react-router';
import { post } from '../../../utils/fetch';
import { URL_BASE } from '../../../constants/url';
import { getRoboDetalles } from '../../../context/actions/robosActions';
import { Localizado } from './steps/Localizado';
import { startGetHistorial } from '../../../context/actions/historialActions';

export const useItemSteps = (currentStatus) => {

  const {
    fechaInicio,
    fechaPrimerContacto,
    fechaSegundoContacto,
    anuladoGNP,
  } = useSelector(state => state.robosReducer.roboDetalles)
  const dispatch = useDispatch()
  const {id} = useParams()
  const handleOnClick = async (tipo) => {
    await post(`${URL_BASE}/api/robos/postFechas`, {id, tipo: tipo})
    void dispatch(startGetHistorial(id, 3));
    void dispatch(getRoboDetalles(id))
  }
  const items = [
    {title: 'Inicio', description: <ClockWithDate date={fechaInicio}/>},
    {
      title: 'Con folio de carpeta',
      description: <ControlStep anulado={anuladoGNP} date={fechaPrimerContacto} title={'Marcar primer contacto'} numStatus={1}
                                onClick={() => handleOnClick(1)}
                                currentStatus={currentStatus}/>
    },
    {
      title: 'Notificación documental',
      description: <ControlStep  anulado={anuladoGNP} title={'Marcar segundo contacto'} date={fechaSegundoContacto} numStatus={2}
                                onClick={() => handleOnClick(2)}
                                currentStatus={currentStatus}/>
    },
    {
      title: 'Concluir',
      description: <Localizado anulado={anuladoGNP}/>,
    },
  ]
  return {items}
}