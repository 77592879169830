import { Button, Col, Row, Upload } from "antd";
import React, { useState } from "react";
import LabelText from "../../components/input/LabelText";
import { postFile } from "../../utils/fetch";
import {
  composeErrorNotification,
  composeSuccessNotification,
} from "../../utils/composeNotification";
import { notification } from "../../context/actions/notificationActions";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { UploadOutlined } from "@ant-design/icons";

export const UploadConNombre = ({ url, dispatch1, placeholder }) => {
  const dispatch = useDispatch();
  const [body, setBody] = useState({});
  const { id } = useParams();

  ///UploadFiles/1gIxEAPXm3?nombre=Pagos
  const handleClick = async () => {
    if (!body.nombre) return;
    if (!body.file) return;

    let formdata = new FormData();
    formdata.append("archivo", body.file);

    formdata.append("mimetype", "application/pdf");

    // const url = `${URL_PT}/UploadFiles/${id}?nombre=${body.nombre}`;
    const result = await postFile(`${url}?nombre=${body.nombre}`, formdata);

    if (result.code === 0) {
      dispatch(notification(composeSuccessNotification(result.data)));
      dispatch1(id);
      //dispatch(startGetPTServicio(id));
      setBody({});
    } else {
      dispatch(
        notification(composeErrorNotification("UploadFiles", `${result.error}`))
      );
    }
  };

  const props = {
    beforeUpload: (file) => {
      setBody({ ...body, file });
      return false;
    },
    maxCount: 1,
    showUploadList: false,
  };

  const handleChange = ({ target }) =>
    setBody({ ...body, nombre: target.value });

  ////
  return (
    <>
      <Row style={{ marginTop: 20 }} gutter={10}>
        <Col span={10}>
          <LabelText
            placeholder={placeholder}
            size="small"
            name="nombre"
            value={body.nombre}
            onChange={handleChange}
            label="Nombre"
            style={{ width: "100%" }}
          />
        </Col>

        <Col span={10}>
          <div className="label">Archivo</div>
          <Upload {...props} style={{ width: "100%" }}>
            <Button size="small" icon={<UploadOutlined />}>
              Seleccione documento
            </Button>
          </Upload>
        </Col>
        <Col span={4} style={{ marginTop: 18 }}>
          <Button danger size="small" onClick={handleClick}>
            Agregar
          </Button>
        </Col>
      </Row>
      <div
        style={{ paddingLeft: 3, paddingTop: 6, height: 17, color: "orange" }}
      >
        {body.file?.name}
      </div>
    </>
  );
};
