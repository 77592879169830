import { Table } from 'antd';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getRobos } from '../../../context/actions/robosActions';
import { useTableData } from './useTableData';

export const TablaRobos = () => {

  // api/robos/GetRobos
  const {columns } = useTableData()

  const robosList = useSelector(state => state.robosReducer.robos)

  const dispatch = useDispatch();

  useEffect(() => {

    void dispatch(getRobos())
    
  }, []);

  return(
    <>
      <Table
        rowKey={'id'}
        columns={columns}
        dataSource={robosList}
      />
    </>
  )
}