import { Button, Col, DatePicker, Input, Row } from "antd";
import React, { useState } from "react";
import { EstatusDropdown } from "../../../components/dropdown/EstatusDropdown";
import { useDispatch, useSelector } from "react-redux";
import {
  setFiltroConcesionarios,
  startGetConcesionariosServicios,
  startGetXLS,
  startGetXLSCostos,
} from "../../../context/actions/concesionariosActions";
import { URL_INCIDENCIAS } from "../../../constants/url";
import axios from "axios";

const { RangePicker } = DatePicker;

////
export const Controles = () => {
  const [ fechaAqui, setFechaAqui ] = useState();
  const { concesionariosServicios, numSiniestro } = useSelector(
    (state) => state.concesionariosReducer
  );
  const dispatch = useDispatch();
  const {
    filtro: { fechas, proveedorNombre, estatusId },
  } = useSelector((state) => state.concesionariosReducer);

  const handleChange = (val, target) =>
    dispatch(setFiltroConcesionarios(val, target));

  const handleFechas = (val1, val2) => {
    dispatch(setFiltroConcesionarios(val2, "fechas"));
    setFechaAqui(val1);
  };

  function downloadFile() {
    axios({
      url: `${URL_INCIDENCIAS}/GetXlsIncidenciasConcesionarios`,
      method: 'GET',
      responseType: 'blob',
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'xlsIncidenciasConcesionarios.xlsx');
      document.body.appendChild(link);
      link.click();
    });
  }


  /////
  return (
    <Row gutter={10} style={{ marginBottom: 5 }}>
      <Col></Col>
      <Col span={5}>
        <RangePicker
          placeholder={[ "Inicio", "Fin" ]}
          format='YYYY-MM-DD'
          size='small'
          value={fechaAqui}
          onChange={handleFechas}
        />
      </Col>
      <Col span={5}>
        <Input
          value={proveedorNombre}
          onChange={({ target }) =>
            handleChange(target.value, "ProveedorNombre")
          }
          size='small'
          placeholder='proveedor'
        />
      </Col>

      <Col span={3}>
        <EstatusDropdown
          onChange={(val) => handleChange(val, "estatusId")}
          value={estatusId}
        />
      </Col>


      <Col span={2}>
        <Input
          value={numSiniestro}
          onChange={({ target }) => dispatch(setFiltroConcesionarios(target.value, "numSiniestro"))}
          size='small'
          placeholder='No. siniestro'
        />
      </Col>


      <Col
      >
        <Button
          onClick={() => dispatch(startGetConcesionariosServicios())}
          size='small'
          danger
        >
          Filtrar
        </Button>
      </Col>

      <Col span={2}>
        <Button
          disabled={concesionariosServicios.length == 0}
          onClick={() => dispatch(startGetXLS())}
          size='small'
          danger
        >
          XLS General
        </Button>
      </Col>
      <Col span={2}>
        <Button
          // disabled={concesionariosServicios.length == 0}
          onClick={() => dispatch(startGetXLSCostos())}
          size='small'
          danger
        >
          XLS Costos
        </Button>
      </Col>

      <Col span={2}>
        <Button
          size='small'
          danger
          onClick={downloadFile}
        >
          Incidencias XLS
        </Button>
      </Col>
    </Row>
  );
};
