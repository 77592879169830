import { Select } from "antd";
import React from "react";

import { opcionesCostos } from "../../constants/const";

export const DescripcionCostos = ({ value, onChange }) => {
  return (
    <Select
      size="small"
      style={{ width: "100%" }}
      value={value}
      onChange={onChange}
      options={opcionesCostos}
    />
  );
};
