import { Button, Col,  message, Row, Switch } from 'antd';
import LabelValor from '../../../../components/label/LabelValor';
import { useItemsLabel } from '../../const/ItemLabel';
import { useSelector } from 'react-redux';
import React, { useRef, useState } from 'react';
import { ModalFormEdit } from './ModalEditDetalles';
import { UseGetFielsDetalles } from '../hooks/useGetFielsDetalles';
import { post } from "../../../../utils/fetch";
import { BASE_URL_ROBOS } from "../../../../constants/url";
import { useParams } from "react-router";

export const Detalles = () => {
  const roboDetalles = useSelector(state => state.robosReducer.roboDetalles);
  const debounceRef = useRef();

  const {firstRow, secondRow, thirdRow, fourthRow, fifthRow} = useItemsLabel(roboDetalles)
  const {id} = useParams()

  const onChange = (e) => {
    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }
    const newVal = e.target.value;
    debounceRef.current = setTimeout(async () => {
      const result = await post(`${BASE_URL_ROBOS}/updateRobo`, {NumeroDocumentos: newVal, id})
      if (result.code === 0) {
        message.success(result.data || 'Cambios guardados');
      } else {
        message.error(result.error);
      }
    }, 1000);
  };

  const [ modalDetallesEdit, setModalDetallesEdit ] = useState(false)
  const {fields} = UseGetFielsDetalles()
  const detalles = useSelector(state => state.robosReducer.roboDetalles);


  const onChangeSwitch = async (value) => {
    const result = await post(`${BASE_URL_ROBOS}/updateRobo`, {Desistimiento: value, id})
    if (result.code === 0) {
      message.success(result.data);
    } else {
      message.error(result.error);
    }

  }
  return (
    <>

      <Row gutter={[ 10, 10 ]} style={{marginBottom: '10px'}}>
        {firstRow.map((data, index) => <Col key={index} span={6}>
          <LabelValor label={data.label} valor={data.value} onClick={data.value ? data.onClick : () => {
          }}/>
        </Col>)}
      </Row>


      <Row gutter={[ 10, 10 ]} style={{marginBottom: '10px'}}>
        {secondRow.map((data, index) => <Col key={index} span={6}>
          <LabelValor label={data.label} valor={data.value} onClick={data.value ? data.onClick : () => {
          }}/>
        </Col>)}
      </Row>


      <Row gutter={[ 10, 10 ]} style={{marginBottom: '10px'}}>
        {thirdRow.map((data, index) => <Col key={index} span={6}>
          <LabelValor label={data.label} valor={data.value} onClick={data.value ? data.onClick : () => {
          }}/>
        </Col>)}
      </Row>

      <Row gutter={[ 10, 10 ]} style={{marginBottom: '10px'}}>
        {fourthRow.map((data, index) => <Col key={index} span={6}>
          <LabelValor label={data.label} valor={data.value} onClick={data.value ? data.onClick : () => {
          }}/>
        </Col>)}
      </Row>

      <Row gutter={[ 10, 10 ]} style={{marginBottom: '10px'}}>
        {fifthRow.map((data, index) => <Col key={index} span={6}>
          <LabelValor label={data.label} valor={data.value} onClick={data.value ? data.onClick : () => {
          }}/>
        </Col>)}

        <Col span={2}>

          <div className="label" style={{marginBottom: '5px'}}>
            Desistimiento
          </div>
          <Switch  defaultValue={roboDetalles.desistimiento} onChange={(value) => onChangeSwitch(value)}/>
        </Col>
        <Col span={2}>

          <div className="label" ref={debounceRef}>
            No. docs
          </div>
          <input defaultValue={roboDetalles.numeroDocumentos} className="valor" style={{width: '100%', border: 'none'}} onChange={onChange}>

          </input>
        </Col>

        <Col span={2}>
          <Button
            onClick={() => setModalDetallesEdit(true)}
            danger
            style={{marginTop: '20px', marginLeft: '30px'}} size="small">Editar</Button>
        </Col>
      </Row>

      <ModalFormEdit
        fields={fields}
        width={1500}
        open={modalDetallesEdit}
        defaultValues={detalles}
        title={'Editar detalles'}
        setOpen={setModalDetallesEdit}/>
    </>
  )
}