export const UseFormLocalizado = () => {
  const rules = [ {required: true, message: 'Campo requerido'} ]
  const fieldsLabel = [
    {label: 'Fecha de localización', name: 'FechaLocalizacion', type: 'date', rules},
    {label: 'Estado de localización', name: 'EstadoLocalizacion', type: 'text', rules},
    {label: 'Ubicación de la unidad', name: 'UbicacionUnidad', type: 'text', rules},
    {label: 'Condición de la unidad', name: 'CondicionUnidad', type: 'text', rules},
    {label: 'Nombre del recuperador', name: 'RecuperadorNombre', type: 'text', rules},
    {label: 'Teléfono del recuperador', name: 'RecuperadorTelefono', type: 'text', rules},
    {label: 'Email del recuperador', name: 'RecuperadorEmail', type: 'text', rules},
    {label: 'Folio de cerco', name: 'FolioCerco', type: 'text', rules},
    {label: 'Responsable', name: 'Responsable', type: 'text', rules},
    {label: 'Periodo de localización', name: 'PeriodoLocalizacion', type: 'text', rules},
    {
      label: 'Quien Libera',
      name: 'AreaLocalizacion',
      type: 'select',
      options: [ {label: 'Escotel', value: 'Escotel'}, {label: 'GNP', value: 'GNP'} ],
      rules
    }
  ]
  return {fieldsLabel}

};
