import React, { useEffect, useState } from "react";
import LabelValor from "../../../../components/label/LabelValor";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { startUpdateServicioPT } from "../../../../context/actions/ptActions";
import { Input, Modal } from "antd";


export const NumAlterno = () => {
  const [newValue, setNewValue] = useState();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const { servicioPT: c } = useSelector((state) => state.ptReducer.ptDetalle);

  const HandleOk = () => {
    void dispatch(startUpdateServicioPT({ numAlterno: newValue, id }));
    setOpen(false);
  };

  useEffect(() => {
    setNewValue(c.numAlterno);
  }, [c.numAlterno]);
  return (
    <>
      <LabelValor
        labelStyle={{ cursor: "pointer", textDecoration: "underline dotted" }}
        label="Tel. alterno"
        valor={c.numAlterno}
        onClick={() => setOpen(true)}
      />

      <Modal
        title="Establecer Teléfono Alterno"
        onCancel={() => setOpen(false)}
        onOk={HandleOk}
        open={open}
      >
        <Input
          value={newValue}
          onChange={({ target }) => setNewValue(target.value)}
        />
      </Modal>
    </>
  );
};
