import { useSelector } from "react-redux";
import LabelValor from "../../../components/label/LabelValor";
import { Col, Divider, message, Row, Switch } from "antd";
import { DayjsConfig } from "../../../utils/dayjs.config";
import { useParams } from "react-router";
import { post } from "../../../utils/fetch";
import { BASE_URL_ROBOS } from "../../../constants/url";
import { CustomLabelWitchChildren } from "../../../shared/CustomLabelWithChildren/CustomLabelWitchChildren";

export const Detalle2 = () => {
  const detallesRobo = useSelector(state => state.robosReducer.roboDetalles);
  const format = DayjsConfig("DD-MM-YY")
  const {id} = useParams()

  const upDateDocumentosCompletos = async (documentosCompletos) => {
    const result = await post(`${BASE_URL_ROBOS}/updateRobo`, {
      id,
      documentosCompletos
    })
    if(result.code ===0){
      message.success(result.data)
    }else {
      message.error(result.error)
    }
  }
  return (
    <>
      <Divider/>
      <Row gutter={[10, 10]}>

        <Col xl={4}>
          <LabelValor label={'Medio primer contacto'} valor={detallesRobo.medioPrimerContacto}/>
        </Col>
        <Col xl={4}>
          <LabelValor label={'Fecha medio primer Contacto'} valor={format(detallesRobo.fechaMedioPrimerContacto)}/>
        </Col>
        <Col xl={4}>
          <CustomLabelWitchChildren label={'Documentos completos'}>
            <Switch onChange={upDateDocumentosCompletos}  defaultChecked={detallesRobo?.documentosCompletos}/>
          </CustomLabelWitchChildren>
        </Col>





        <Col xl={4}>
          <LabelValor label={'Notificar no contactado'} valor={detallesRobo.notificarNoContactado ? 'Si' : 'NO'}/>
        </Col>

        <Col xl={4}>
          <LabelValor label={'Razón no contactado'} valor={detallesRobo.razonNoContactado}/>
        </Col>

      </Row>

    </>

  )
}