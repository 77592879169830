import { Select } from "antd";
import React from "react";
import { opcionesMotivoIncidenciaPT, opcionesResponsableIncidenciaPT } from "../../constants/const";

export const ResponsableIncidenciaPTDropdown = ({ value, onChange }) => {
  return (
    <Select
      size="small"
      style={{ width: "100%" }}
      value={value}
      onChange={onChange}
      options={opcionesResponsableIncidenciaPT}
      placeholder="Responsable"
    />
  );
};
