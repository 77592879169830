import { Button, Drawer } from "antd";
import React, { useState } from "react";
import { DetalleCostos } from "./_DetalleCostos";
import { useSelector } from "react-redux";

export const Incidencias = () => {
  const { totalIncidencias, totalIncidenciasAbiertas } = useSelector(
    (state) => state.concesionariosReducer.concesionariosServicio
  );
  const [open, setOpen] = useState(false);
  return (
    <>
      <div>
        <Button style={{ width: "100%" }} onClick={() => setOpen(true)} danger>
          Incidencias ({totalIncidenciasAbiertas}/{totalIncidencias})
        </Button>
      </div>

      <Drawer
        title="Incidencias"
        placement="right"
        onClose={() => setOpen(false)}
        open={open}
      >
        <DetalleCostos />
      </Drawer>
    </>
  );
};
