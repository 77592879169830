import React from "react";
import { Row10 } from "../../../components/row/Row10";
import { Col_12_8_6_4 } from "../../../components/col/Col_12_8_6_4";
import LabelValor from "../../../components/label/LabelValor";
import { useSelector } from "react-redux";
import { Col_doble } from "../../../components/col/Col_doble";
import { SkeletonPage } from "../../../layout/SkeletonPage";
import { Guia } from "./inputs/_Guia";
import { Refacturar } from "./inputs/_Refacturar";
import { Incidencias } from "./incidencias/Incidencias";
import { NumSiniestro } from "./inputs/_NumSiniestro";
import { NumCelular } from "./inputs/_NumCelular";
import { NumFijo } from "./inputs/_NumFijo";
import { NumAlterno } from "./inputs/_NumAlterno";
import { NumPoliza } from "./inputs/_NumPoliza";
import { useNavigate } from 'react-router';

export const Seccion1 = () => {
  const {servicioPT: d} = useSelector((state) => state.ptReducer.ptDetalle);
  const navigate = useNavigate();
  if (!d) return <SkeletonPage/>;
  return (
    <>
      <Row10>
        <Col_12_8_6_4>
          <LabelValor label="Fecha de registro" valor={d.fechaRegistro}/>
        </Col_12_8_6_4>
        <Col_12_8_6_4>
          <LabelValor label="Fecha de Siniestro" valor={d.fechaSiniestro}/>
        </Col_12_8_6_4>
        <Col_12_8_6_4>
          <LabelValor label="Inicio" valor={d.fechaInicioLLamadas}/>
        </Col_12_8_6_4>
        <Col_12_8_6_4>
          <LabelValor label="RoboID" valor={d.roboID} onClick={d.roboID ? () => navigate(`/roboDetalle/${d.roboID}`) : ( ) => {} }/>
        </Col_12_8_6_4>


        <Guia/>
        <Refacturar/>
        {/*{d.roboID && <VoBoRobos voBoRobos={d.voBoRobos}/>}*/}


        <Incidencias/>
      </Row10>

      <Row10>
        <Col_12_8_6_4>
          <NumSiniestro/>
        </Col_12_8_6_4>

        <Col_12_8_6_4>
          <LabelValor label="Fólio" valor={d.idUnico}/>
        </Col_12_8_6_4>
        <Col_12_8_6_4>
          <NumPoliza/>
        </Col_12_8_6_4>
        <Col_12_8_6_4>
          <LabelValor label="Cobertura" valor={d.cobertura}/>
        </Col_12_8_6_4>
        <Col_doble>
          <LabelValor label="Marca" valor={d.marca}/>
        </Col_doble>
        <Col_doble>
          <LabelValor label="Tipo" valor={d.tipo}/>
        </Col_doble>{" "}
        <Col_doble>
          <LabelValor label="Modelo" valor={d.modelo}/>
        </Col_doble>{" "}
        <Col_doble>
          <LabelValor label="Serie" valor={d.numSerie}/>
        </Col_doble>{" "}
        <Col_doble>
          <LabelValor label="Asegurado" valor={d.nombreAsegurado}/>
        </Col_doble>{" "}
        <Col_doble>
          <LabelValor label="Propietario" valor={d.nombrePropietario}/>
        </Col_doble>{" "}
        <Col_doble>
          <LabelValor label="Régimen" valor={d.regimenFiscalRobo}/>
        </Col_doble>{" "}
        <Col_doble>
          <NumCelular/>
        </Col_doble>{" "}
        <Col_doble>
          <NumFijo/>
        </Col_doble>{" "}
        <Col_doble>
          <NumAlterno/>
        </Col_doble>{" "}
        <Col_doble>
          <LabelValor label="Correo" valor={d.correo}/>
        </Col_doble>{" "}
        <Col_doble>
          <LabelValor label="Comentarios GNP" valor={d.comentariosGNP}/>
        </Col_doble>{" "}
        <Col_doble>
          <LabelValor label="Lugar siniestro" valor={d.lugarSiniestro}/>
        </Col_doble>
      </Row10>
    </>
  );
};
