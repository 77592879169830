import { Navigate, Route, Routes } from "react-router-dom";
import { Home } from "../views/home/Home";

import { LayoutPage } from "../layout/LayoutPage";
import {
  ConsesionariosServicios
} from "../views/serviciosConsesionarios/consesionariosServicios/ConsesionariosServicios";
import { ConsesionariosDetalle } from "../views/serviciosConsesionarios/consesionariosDetalle/ConsesionariosDetalle";
import { ConsesionariosCarga } from "../views/serviciosConsesionarios/consesionariosCarga/ConsesionariosCarga";
import { PTCarga } from "../views/serviciosPT/PTCarga/PTCarga";
import { PTDetalle } from "../views/serviciosPT/PTDetalle/PTDetalle";
import { PTservicios } from "../views/serviciosPT/PTservicios/Servicios";
import { ListaIncidencias } from "../views/serviciosPT/ListaIncidencias/ListaIncidencias";
import { ListaRefactura } from "../views/serviciosPT/ListaRefactura/ListaRefactura";
import { useDispatch, useSelector } from "react-redux";
import { areaToShowCargarDatos } from "../constants/const";
import { startGetStatusCat } from '../context/actions/concesionariosActions';
import { useEffect } from 'react';
import { RobosCarga } from '../views/robos/robosCarga/RobosCarga';
import ListaRobos from '../views/robos/listadoRobos/ListaRobos';
import { RoboDetalle } from '../views/robos/roboDetalles/RoboDetalle';

export const ProtectedRoutes = () => {
  const area = useSelector(state => state.userReducer.user.area)
  const dispatch = useDispatch()
  useEffect(() => {

    dispatch(startGetStatusCat())

  }, []);

  return (
    <>
      <LayoutPage>
        <Routes>
          <Route path="home" element={<Home/>}/>

          <Route path="/" element={<Navigate to="/home"/>}/>

          <Route
            path="/ConsesionariosServicios"
            element={<ConsesionariosServicios/>}
          />

          <Route
            path="/ConsesionariosDetalle/:id"
            element={<ConsesionariosDetalle/>}
          />

{/*
        <Route
          path={"/admin-users/:id"}
          element={<UserDetails/>}
        />
*/}

          <Route
            path="/ConsesionariosCarga"
            element={<ConsesionariosCarga/>}
          />

          <Route path="/PTservicios" element={<PTservicios/>}/>


          <Route path="/PTDetalle/:id" element={<PTDetalle/>}/>

          {areaToShowCargarDatos.includes(area?.toLowerCase()) && <Route path="/PTCarga" element={<PTCarga/>}/>}

          <Route path="/ListaRefactura" element={<ListaRefactura/>}/>

          <Route path="/ListaIncidencias" element={<ListaIncidencias/>}/>

          {/* Servicios robo*/}

          <Route path="/robosCarga" element={<RobosCarga/>} />

          <Route path="/ListaRobos" element={ <ListaRobos/> } />

          <Route path="/RoboDetalle/:id" element={<RoboDetalle/>}/>

          {/*<Route path="/admin-users" element={ <AdminUsers/>}/>*/}

        </Routes>
      </LayoutPage>
    </>
  );
};
