import React from "react";
import { dateFromString } from "../../../../utils/dateFromString";

export const HistorialItem = ({ historial: { fecha, nombre, texto } }) => {
  return (
    <div>
      <div style={{ color: "orange" }}>
        {dateFromString(fecha).dateStep} {nombre}
      </div>
      <div>{texto}</div>
    </div>
  );
};
