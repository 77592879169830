import { Tag } from "antd";
import React from "react";
import { returnPTStatus } from "../../utils/returnPTStatus";

export const EstatusPT_Pill = ({ renglon: { estatusId } }) => {
  let color = "#a8a8a8";
  if (estatusId === 2) color = "#8e915a";
  if (estatusId === 3) color = "#8d9ef0";
  if (estatusId === 4) color = "#778cf7";
  if (estatusId === 5) color = "#0827c2";
  if (estatusId === 6) color = "#97f097";
  if (estatusId === 7) color = "#057805";

  return (
    <Tag style={{ width: "100%", textAlign: "center" }} color={color}>
      {returnPTStatus(estatusId)}
    </Tag>
  );
};
