import { Steps } from "antd";
import React from "react";
import { Inicio } from "./inicio/Inicio";

import { useSelector } from "react-redux";
import { ClienteContactado } from "./clienteContactado/ClienteContactado";
import { RecolectandoDoc } from "./recolectandoDoc/RecolectandoDoc";
import { ValidadoGNP } from "./validadoGNP/ValidadoGNP";
import { GuiaSolicitada } from "./guiaSolicitada/GuiaSolicitada";
import { GuiaConfirmada } from "./guiaConfirmada/GuiaConfirmada";
import { DocEntregada } from "./docEntregada/DocEntregada";
import {
  tituloEstatus2,
  tituloEstatus3,
  tituloEstatus4,
  tituloEstatus5,
  tituloEstatus6,
  tituloEstatus7,
} from "./titulos";

const { Step } = Steps;

export const Timeline = () => {
  const { estatusId } = useSelector(
    (state) => state.ptReducer.ptDetalle.servicioPT
  );


  return (
    <Steps size="small" current={estatusId}>
      <Step title="Inicio" description={<Inicio />} />
      <Step
        title={tituloEstatus2(estatusId)}
        description={<ClienteContactado />}
      />
      <Step
        title={tituloEstatus3(estatusId)}
        description={<RecolectandoDoc />}
      />
      <Step title={tituloEstatus4(estatusId)} description={<ValidadoGNP />} />
      <Step
        title={tituloEstatus5(estatusId)}
        description={<GuiaSolicitada />}
      />
      <Step
        title={tituloEstatus6(estatusId)}
        description={<GuiaConfirmada />}
      />
      <Step title={tituloEstatus7(estatusId)} description={<DocEntregada />} />
    </Steps>
  );
};
