import pjson from "../../package.json";

export const ver = pjson.version;
export const nameProject = pjson.name;
export const { ambiente } = window["runConfig"];
export const { URL_BASE } = window["runConfig"];

////

export const URL_BASE_VER = `${URL_BASE}/api/Ver`;

export const URL_AUTH = `${URL_BASE}/api/auth`;
export const URL_CONCESIONARIOS = `${URL_BASE}/api/Concesionarios`;
export const URL_INCIDENCIAS = `${URL_BASE}/api/Incidencias`;
export const URL_PT = `${URL_BASE}/api/PerdidaTotal`;
export const URL_FLUJO_REFACTURA_PT = `${URL_BASE}/api/FlujoRefacturaPT`;
export const URL_HISTORIAL = `${URL_BASE}/api/Historial`;
export const BASE_URL_ROBOS = `${URL_BASE}/api/robos`;
