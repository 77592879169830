import { Steps } from "antd";
import React from "react";
import { Inicio } from "./inicio/Inicio";
import { EnProceso } from "./enProceso/EnProceso";
import { Negociando } from "./negociando/Negociando";
import { EnDocumentacion } from "./enDocumentacion/EnDocumentacion";
import { Documentado } from "./documentado/Documentado";
import { Pagado } from "./pagado/Pagado";
import { Liberado } from "./liberado/Liberado";
import { useSelector } from "react-redux";
const { Step } = Steps;

export const Timeline = () => {
  const { estatusId } = useSelector(
    (state) => state.concesionariosReducer.concesionariosServicio
  );
  return (
    <Steps size="small" current={estatusId}>
      <Step title="Inicio" description={<Inicio />} />
      <Step title="En proceso" description={<EnProceso />} />
      <Step title="Negociando" description={<Negociando />} />
      <Step title="En documentación" description={<EnDocumentacion />} />
      <Step title="Documentado" description={<Documentado />} />
      <Step title="Pagado " description={<Pagado />} />
      <Step title="Liberado " description={<Liberado />} />
    </Steps>
  );
};
