import React, { useState } from "react";
import {
  CheckCircleOutlined,
  CloseOutlined,
  CloudDownloadOutlined,
  EditOutlined,
  InfoCircleOutlined
} from '@ant-design/icons';

import styles from './ListFile.module.css'
import { ModalEditStatusDoc } from './ModalEditStatusDoc';
import { Col, Row, Tooltip } from "antd";

const colorsByStatus = {
  'Pendiente': <Tooltip title={'Pendiente'}><InfoCircleOutlined style={{color: 'blue'}} className={styles.status}/>
  </Tooltip>,
  'aprobado': <Tooltip title={'Aprobado'}> <CheckCircleOutlined style={{color: 'green'}} className={styles.status}/>
  </Tooltip>,
  'rechazado': <Tooltip title={"Rechazado"}><CloseOutlined style={{color: 'red'}} className={styles.status}/>
  </Tooltip>,
}

export const ListFile = ({doc, onClick}) => {

  const [ open, setOpen ] = useState(false)

  return (
    <>
      <div className={styles.file}>
        <Row gutter={[ 10, 10 ]} className={styles.row_item} style={{alignItems: 'center'}}>
          <Col span={18}>

            {doc.nombreArchivo}
          </Col>
          <Col span={6}>

            <div style={{display: 'flex', alignItems: 'center', gap: '20px',}}>
{/*

              //TODO: Activar cuando se libere gnp-asegurados
              <Tooltip title={"Editar"}>
                < EditOutlined onClick={() => setOpen(true)} className={styles.edit_icon}/>
              </Tooltip>
*/}

              <Tooltip title={"Descargar"}>

                <CloudDownloadOutlined className={styles.download_icon}
                                       onClick={() => onClick(doc)}/>
              </Tooltip>


              {/*{colorsByStatus[`${doc.estatusDocumento}`]}*/}
            </div>
          </Col>


        </Row>


{/*
        <p style={{
          textAlign: 'center',
          margin: '0px',
          fontWeight: '600',
          padding: '2px',
          color: '#ca2228'
        }}>{doc?.motivoRechazo}</p>
*/}

      </div>
      <ModalEditStatusDoc docId={doc.id} open={open} setOpen={setOpen}/>
    </>
  )
};
