import { concesionariosReducer } from "../reducers/concesionariosReducer";
import { notificationReducer } from "../reducers/notificationReducer";
import { ptReducer } from "../reducers/ptReducer";
import { userReducer } from "../reducers/userReducer";
import { historialReducer } from "../reducers/historialReducer";
import { flujoRefacturaReducer } from "../reducers/flujoRefacturaReducer";
import { robosReducer } from '../reducers/robosReducer';
import { adminReducer } from '../reducers/adminReducer'

export const allReducers = {
  notificationReducer,
  userReducer,
  concesionariosReducer,
  ptReducer,
  historialReducer,
  flujoRefacturaReducer,
  robosReducer,
  adminReducer
};
