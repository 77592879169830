import { Col, Flex, message, Row } from 'antd';

import { post } from '../../../../utils/fetch';
import { BASE_URL_ROBOS } from '../../../../constants/url';
import { useEffect } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { FormComunication } from '../FormComunicación';
import { TimeLineComunicacion } from '../steps/TimeLineComunicacion';
import { getComunicaciones } from '../../../../context/actions/robosActions';
import { useInput } from "./UseInput";
import { ButtonEdit } from "./ButtonEdit";


export const InfoEdit = () => {
  const roboDetalles = useSelector(state => state.robosReducer.roboDetalles);
  const [changeFolio, onChangeText] = useInput(roboDetalles.folioCarpetaInvestigacion, 'FolioCarpetaInvestigacion');
  const [anotherEmail, onChangeEmail] = useInput(roboDetalles.correoAdicional, 'CorreoAdicional');
  const [anotherPhone, onChangePhone] = useInput(roboDetalles.numAdicional, 'numAdicional');
  const {id} = useParams();

  const motivos = [
    {label: 'Número fuera de servicio', value: 'numero fuera de servicio'},
    {label: 'Número incorrecto', value: 'numero incorrecto'},
    {label: 'Integración de expediente', value: 'integracion de expediente'},
    {label: 'Desviación en la integración', value: 'desviciacion en la intragracion'},
    {label: 'Anticipación de PT', value: 'Anticipación de PT'},
  ]
  const dispatch = useDispatch();


  useEffect(() => {
    void dispatch(getComunicaciones(id, 'inicial'))
  }, []);

  const comuIncial = useSelector(state => state.robosReducer.comunicacionesInicial);

  const onFinish = async (values) => {
    values.id = +id
    values.tipo = 'inicial'
    const result = await post(`${BASE_URL_ROBOS}/postComunicacion`, values)
    if (result.code === 0) {
      message.success('Comunicación guardada');
    } else {
      message.error('Error al guardar comunicación');
    }

    void dispatch(getComunicaciones(id, 'inicial'))
  }


  return (
    <Flex gap={30}>
      <div style={{width: '50%'}}>
        <Row gutter={[10, 10]} style={{marginBottom: '10px'}}>
          <ButtonEdit/>
          <Col span={24}>
            <div className="label">
              Folio carpeta de investigación
            </div>
            <input
              value={changeFolio ?? roboDetalles.folioCarpetaInvestigacion}
              className="valor" onChange={onChangeText}
              style={{border: 'none', cursor: 'pointer', width: '100%'}}
            />
          </Col>
          <Col span={24}>
            <div className="label">
              Número adicional
            </div>
            <input value={anotherPhone ?? roboDetalles.numAdicional} className="valor" onChange={onChangePhone}
                   style={{border: 'none', cursor: 'pointer', width: '100%'}}
            />
          </Col>
          <Col span={24}>
            <div className="label">
              Correo adicional
            </div>
            <input value={anotherEmail ?? roboDetalles.correoAdicional} className="valor" onChange={onChangeEmail}
                   style={{border: 'none', cursor: 'pointer', width: '100%'}}
            />
          </Col>
        </Row>
      </div>
      <div style={{width: '50%'}}>
        <FormComunication motivos={motivos} onFinish={onFinish} title={'Agregar comunicación inicial'}
                          styleTitle={{margin: '0', marginBottom: '10px', fontSize: '14px'}}/>
        <TimeLineComunicacion tipo={'inicial'} style={{maxHeight: '120px', overflow: 'auto'}} list={comuIncial}/>
      </div>
    </Flex>)
}
