import React, { useEffect, useState } from 'react';
import CardPage from '../../../layout/CardPage';
import StepsRobo from './StepsRobo';
import { Divider } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { DetallesLocalizacion } from './components/DetallesLocalizacion';
import { InfoEdit } from './components/InfoEdit';
import { Detalles } from './components/Detalles';
import { FooterDetalles } from './components/FooterDetalles';
import { getRoboDetalles, storeRobosDetalles } from '../../../context/actions/robosActions';
import { useParams } from 'react-router';
import { Detalle2 } from "./Detalle2";


export const RoboDetalle = () => {

  const roboDetalles = useSelector(state => state.robosReducer.roboDetalles);
  const [ loading, setLoading ] = useState(true)
  const {id} = useParams()
  const dispatch = useDispatch();

  useEffect(() => {
    void dispatch(getRoboDetalles(id)).then(() => setLoading(false))
    return () => {
      dispatch(storeRobosDetalles({}))
    }
  }, []);

  if (loading) {
    return <div>loading...</div>
  }
  return (
    <>
      <CardPage titulo={ 'Detalles robo' }>

        <Detalles/>
        <Detalle2/>

        <section style={ {marginTop: '30px'} }>
          <InfoEdit/>
        </section>

        { roboDetalles?.roboEstatusFinalID === 2 &&
          <>
            <Divider type="horizontal"/>
            <DetallesLocalizacion/>
          </>
        }


        <Divider type="horizontal"/>

        <StepsRobo/>

        {/*comunicación e historial*/ }

        <FooterDetalles/>

      </CardPage>

    </>
  )
}