import React, { useEffect, useState } from "react";
import { Col_12_8_6_4 } from "../../../../components/col/Col_12_8_6_4";
import LabelValor from "../../../../components/label/LabelValor";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { startUpdateServicioPT } from "../../../../context/actions/ptActions";
import { Input, Modal } from "antd";

export const NumFijo = () => {
  const [newValue, setNewValue] = useState();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const { servicioPT: c } = useSelector((state) => state.ptReducer.ptDetalle);

  const HandleOk = () => {
    dispatch(startUpdateServicioPT({ numFijo: newValue, id }));
    setOpen(false);
  };

  useEffect(() => {
    setNewValue(c.numFijo);
  }, [c.numFijo]);
  return (
    <>
      <LabelValor
        labelStyle={{ cursor: "pointer", textDecoration: "underline dotted" }}
        label="Tel. fijo"
        valor={c.numFijo}
        onClick={() => setOpen(true)}
      />

      <Modal
        title="Establecer Teléfono Fijo"
        onCancel={() => setOpen(false)}
        onOk={HandleOk}
        open={open}
      >
        <Input
          value={newValue}
          onChange={({ target }) => setNewValue(target.value)}
        />
      </Modal>
    </>
  );
};
