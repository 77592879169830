import { Provider } from "react-redux";
import { store } from "./context/store/store";

import { BrowserRouter } from "react-router-dom";
import { AppRouter } from "./routes/ApppRoute";
import { message } from "antd";

function App() {

  const [_messageApi, contextHolder] = message.useMessage();
  return (
    <Provider store={store}>

      {contextHolder}
      <BrowserRouter>
        <AppRouter />
      </BrowserRouter>
    </Provider>
  );
}

export default App;
