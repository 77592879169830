import { Button, DatePicker, Form, Input, message, Modal, Radio, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { put } from "../../../../utils/fetch";
import { URL_PT } from "../../../../constants/url";
import { useParams } from "react-router";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { setBatch } from "react-redux/es/utils/batch";
import LabelValor from "../../../../components/label/LabelValor";
import { DayjsConfig } from "../../../../utils/dayjs.config";
import { EditOutlined } from "@ant-design/icons";
import { startGetPTServicio } from "../../../../context/actions/ptActions";

export const FormInputDateLastContact = () => {


  const {servicioPT} = useSelector((state) => state.ptReducer.ptDetalle);
  const [open, setOpen] = useState(false)

  const {id} = useParams();

  const format = DayjsConfig("DD-MM-YYYY");
  const dispatch = useDispatch()
  const options = [
    {label: 'Fecha último contacto 1', value: 1},
    {label: 'Fecha último contacto 2', value: 2},
  ];

  const handleFechaUltimoContacto = async (tipoFecha, fecha) => {
    if (!fecha) return
    const result = await put(`${URL_PT}/updateFechaUltimoContacto/${id}`, {tipoFecha, fecha})
    if (result.code === 0) {
      message.success(result.data)
      dispatch(startGetPTServicio(id))
      setOpen(false)
    } else {
      message.error(result.error)
    }
  }


  const onFinish  = (values) => {
    handleFechaUltimoContacto(values.tipoFecha, values.fecha)
  }
  return (
    <>
      <div style={{display: 'flex', gap: '10px', alignItems: 'center'}}>
        <div>

          <LabelValor label={"Fecha último contacto 1"} valor={servicioPT.fechaUltimoContacto1 && format(servicioPT.fechaUltimoContacto1)}/>
        </div>
        <div style={{marginRight: "20px"}}>

          <LabelValor label={"Fecha último contacto 2"} valor={servicioPT.fechaUltimoContacto2 &&format(servicioPT.fechaUltimoContacto2)}/>
        </div>
        <div>
          <Button onClick={() => setOpen(true)} icon={<EditOutlined/>} danger type={'text'} size={'small'}></Button>
        </div>
      </div>
      <Modal
        title={"Editar Fecha Ultimo contacto"}
        open={open}
        onCancel={() => setOpen(false)}
        footer={null}
      >
        <Form
          onFinish={onFinish}
          layout={"vertical"}
          initialValues={{tipoFecha: 1}}
        >
          <Form.Item
            rules={[{required: true}]}
            name={"tipoFecha"}
            label={'Fecha a actualizar'}
          >
            <Radio.Group
              options={options}
              optionType="button"
              buttonStyle="solid"
            />

          </Form.Item>
          <Form.Item
            rules={[{required: true, message: 'Por favor seleccione una fecha'}]}
            name={'fecha'}
           label={"Fecha"}>
            <DatePicker/>
          </Form.Item>
          <Button htmlType={'submit'} type={'primary'}>Actualizar</Button>
        </Form>
      </Modal>
    </>
  )
}