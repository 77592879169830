import { Button, Col, Row, Space, Switch } from "antd";
import React, { useEffect, useState } from "react";
import LabelText from "../../../../components/input/LabelText";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { startUpdateServicio } from "../../../../context/actions/concesionariosActions";

export const Anulado = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const [newMotivoAnulado, setNewMotivoAnulado] = useState();

  const { anuladoGNP, motivoAnulado } = useSelector(
    (state) => state.concesionariosReducer.concesionariosServicio
  );
  //
  useEffect(() => {
    setNewMotivoAnulado(motivoAnulado);
  }, [motivoAnulado]);

  const hanldeChange = (val) =>
    dispatch(startUpdateServicio({ anuladoGNP: val }, id));

  const handleClick = (val) =>
    dispatch(startUpdateServicio({ motivoAnulado: newMotivoAnulado }, id));

  ///
  return (
    <>
      <Row style={{ marginBottom: 10 }}>
        <Col>
          <Space direction="vertical">
            <Switch
              checked={anuladoGNP}
              onChange={hanldeChange}
              checkedChildren="Si"
              unCheckedChildren="No"
            />
          </Space>
        </Col>
        <Col>
          <div className="label">Anulado GNP</div>
        </Col>
      </Row>
      {anuladoGNP === true && (
        <Row gutter={10}>
          <Col span={20}>
            <LabelText
              value={newMotivoAnulado}
              onChange={({ target }) => setNewMotivoAnulado(target.value)}
              size="small"
              label="motivo de anulación"
            />
          </Col>
          <Col span={4} style={{ paddingTop: 17 }}>
            <Button onClick={handleClick} size="small" danger>
              ok
            </Button>
          </Col>
        </Row>
      )}
    </>
  );
};
