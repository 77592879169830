import { Button, Flex, Form, Input } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { startPostHistorial } from '../../../context/actions/historialActions';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';

const {TextArea} = Input
export const FormHistorial = () => {
  const { id: IdServicio } = useParams();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const handleClick = (values) => {
    // TODO: Preguntar tipo de serivicio
    void dispatch(startPostHistorial({ IdServicio, texto: values.comentario, tipoServicio: 3 }));
    form.resetFields();

  };
  return (
    <>
      <Form
        form={form}
        onFinish={handleClick}
      >
        <Form.Item name={'comentario'}>
          <TextArea/>
        </Form.Item>
        <Flex justify="flex-end">
          <Button htmlType="submit" danger size="small" icon={<SaveOutlined />}>
            Guardar
          </Button>
        </Flex>
      </Form>
    </>
  );
};