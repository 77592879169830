import { Button, Col, Row, Space, Switch } from "antd";
import React, { useEffect, useState } from "react";
import LabelText from "../../../../components/input/LabelText";
import { MotivoAuthDropdown } from "../../../../components/dropdown/MotivoAuthDropdown";
import { useDispatch, useSelector } from "react-redux";
import { startUpdateServicio } from "../../../../context/actions/concesionariosActions";
import { useParams } from "react-router";

export const RequiereAutorizacion = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const [newMotivoAutorizacion, setNewMotivoAutorizacion] = useState();
  const { requiereAutorizacion, motivoAutorizacion } = useSelector(
    (state) => state.concesionariosReducer.concesionariosServicio
  );

  //

  useEffect(() => {
    setNewMotivoAutorizacion(motivoAutorizacion);
  }, [motivoAutorizacion]);

  const handleClick = (val) =>
    dispatch(
      startUpdateServicio({ motivoAutorizacion: newMotivoAutorizacion }, id)
    );
  /////////
  return (
    <>
      <Row style={{ marginBottom: 10 }}>
        <Col>
          <Space direction="vertical">
            <Switch
              checked={requiereAutorizacion}
              onChange={(val) =>
                dispatch(startUpdateServicio({ requiereAutorizacion: val }, id))
              }
              checkedChildren="Si"
              unCheckedChildren="No"
            />
          </Space>
        </Col>
        <Col>
          <div className="label">Requiere autorización</div>
        </Col>
      </Row>
      {/* {requiereAutorizacion === true && (
        <>
          <div className="label">Motivo autorización</div>
          <Row gutter={10}>
            <Col span={20}>
              <MotivoAuthDropdown
                value={newMotivoAutorizacion}
                onChange={(val) => setNewMotivoAutorizacion(val)}
              />
            </Col>
            <Col span={4} style={{}}>
              <Button onClick={handleClick} size="small" danger>
                ok
              </Button>
            </Col>
          </Row>
        </>
      )} */}
    </>
  );
};
