
import { types } from "../types/types";

const initial = {
  notification: {
    message: "",
    description: "",
    type: "",
    dateTime: "",
  },
  backendVer: "...",

}


export const notificationReducer = (state = initial, action) => {
  switch (action.type) {

    case types.storeBackendVer:
      return {
        ...state,
        backendVer: action.payload,
      };
    case types.notification:
      return {
        ...state,
        notification: action.payload,
      };

    default:
      return state;
  }
};
