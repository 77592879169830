import { Table } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import useColums from "./useColums";
import { useNavigate } from "react-router";

export const Tabla = () => {
  const navigate = useNavigate();
  const { ptServicios } = useSelector((state) => state.ptReducer);

  const { colums } = useColums();
  return (
    <Table
      rowClassName="renglonTabla"
      size="small"
      rowKey="id"
      columns={colums}
      dataSource={ptServicios}
      //className="tabla"
      // onRow={(record, rowIndex) => {
      //   return {
      //     onClick: () => navigate(`/PTDetalle/${record.id}`),
      //   };
      // }}
    />
  );
};
